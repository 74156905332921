import exp from "constants";

export interface AuthModel {
  access: string
  refresh?: string
}

interface AccountBase {
  email: string
  full_name: string
  phone: string
  referrer?: string
  instagram_handle: string
  occupation: string
  institution?: string
  discord_username: string
}

export const baseEdit: IAccountEdit= {
  full_name: "",
  phone: "",
  instagram_handle: "",
  occupation: "",
  institution: "",
  discord_username: "",
}

export interface IAccountEdit extends Omit<Partial<AccountBase>, 'referrer' | 'email'> {}

export interface ICreateAccount extends AccountBase{
  password: string
}

export interface UserModel extends AccountBase{
  id: number
  role: string
  referral_code: string
  joined_discord: string
  referred_participating_members: number
  referred_members: number
  participated_contest_count: number
}

export interface ContestModel {
  id: number
  name: string
  description: string
  start: Date | string
  end: Date | string
  open: boolean
  joined: boolean
}

export interface ContestSubmissionModel {
  id: number
  contest: string
  submission_link: string
  winner: boolean
  graded: boolean
  feedback: string
}