/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Button, Card, Table} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {DriverCUModal} from './components/DriverCUModal'
import {BriefDriverAssignmentSchema} from './Model'
import {useNavigate} from 'react-router-dom'
import {listAssignment} from './Api'


export const AssignmentList: React.FC<{}> = ({}) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedAssignment, setSelectedAssignment] = useState<BriefDriverAssignmentSchema | undefined>(undefined)
    const [assignmentList, setAssignmentList] = useState<BriefDriverAssignmentSchema[]>([])

    const updateDriverList = async () => {
        setAssignmentList(await listAssignment())
    }

    const navigate = useNavigate()

    const createCallBack = () => {
        setShowModal(false)
    }

    const updateCallBack = () => {
        setShowModal(false)
    }

    const cancelCallBack = () => {
        setShowModal(false)
        setSelectedAssignment(undefined)
    }


    useEffect(() => {
        updateDriverList()
    }, [])

    return (
        <div className={'card card-xl-stretch mb-xl-8'}>
            <div className={'card-header border-0 pt-5'}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Assignment List</span>
                </h3>
                <div className="card-toolbar">
                    <Button variant={'primary'} onClick={() => {
                        setSelectedAssignment(undefined)
                        setShowModal(true)
                    }}>Create<KTIcon iconName="document"
                                     className="btn-icon fs-2 m-0" /></Button>
                </div>
            </div>


            <div className={'card-body py-3'}>
                <Card.Title>Assignment List</Card.Title>
                <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bold text-muted">
                        <th className="min-w-150px">ID</th>
                        <th className="min-w-120px">Driver Name</th>
                        <th className="min-w-120px">Type</th>
                        <th className="min-w-20px">Origin</th>
                        <th className="min-w-20px">Planned Date Time</th>
                        <th className="min-w-20px">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {assignmentList.map(assignment => (
                        <tr key={assignment.id} className={'cursor-pointer'} style={{cursor: 'pointer !important'}}
                            onClick={() => {
                                navigate(assignment.id)
                            }}>
                            <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {assignment.id}
                                </span>
                            </td>
                            <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {assignment.driverName}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assignment.type}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assignment.startName}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assignment.date}, {assignment.plannedStartTime}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assignment.status}
                                </span>
                            </td>
                        </tr>
                    ))}
                    <>
                    </>

                    </tbody>
                </Table>
            </div>

            {showModal &&
                <DriverCUModal isUpdate={false} callbackUpdate={updateCallBack} callbackCreate={createCallBack}
                               callbackCancel={cancelCallBack} />}
        </div>
    )
}

