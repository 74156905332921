import {BriefDriverAssignmentSchema, DriverAssignmentScheme, DriverCreateScheme, DriverDetailScheme} from './Model'
import {expDriver, expDriverAsg, expDriverAsgs, expLMDriverAsg} from './sampleData'

export const createDriver = async (driver: DriverCreateScheme): Promise<DriverDetailScheme> => {
    // POST /driver/
    throw new Error('Not implemented')
}

export const updateDriver = async (driver: DriverDetailScheme): Promise<DriverDetailScheme> => {
    // PATCH /driver/:driverId
    throw new Error('Not implemented')
}

export const getDriver = async (driverId: string): Promise<DriverDetailScheme> => {
    // GET /driver/:driverId
    if (driverId === '1230') throw new Error('Not found')
    return expDriver
}

export const listDriver = async (): Promise<DriverDetailScheme[]> => {
    // GET /driver/
    return [expDriver]
    // throw new Error('Not implemented')
}
export const getDriverAssignment = async (driverId: string): Promise<BriefDriverAssignmentSchema[]> => {
    // GET /driver/:driverId/assignment
    return expDriverAsgs
}

export const getSelfDriver = async (): Promise<DriverDetailScheme> => {
    // GET /driver/self
    return expDriver
    // throw new Error('Not implemented')
}

export const getSelfAssignment = async () => {
    // GET /driver/self/assignment
    return [expDriverAsg]
}

export const getAssignmentData = async (assignmentId: string): Promise<DriverAssignmentScheme> => {
    // GET /assignment/:assignmentId
    if (assignmentId === '1230') throw new Error('Not found')
    if (assignmentId === '2') return expLMDriverAsg

    return expDriverAsg
    // throw new Error('Not implemented')
}

export const listAssignment = async (): Promise<BriefDriverAssignmentSchema[]> => {
    // GET /assignment/
    return expDriverAsgs
    // throw new Error('Not implemented')
}

export const updateAssignment = async (assignment: DriverAssignmentScheme): Promise<DriverAssignmentScheme> => {
    // PATCH /assignment/:assignmentId
    return assignment
    // throw new Error('Not implemented')
}

// Assignment actions

export const startAssignment = async (assignmentId: string): Promise<boolean> => {
    // POST /assignment/:assignmentId/start
    return true
}

export const startPickupAssignment = async (assignmentId: string): Promise<boolean> => {
    // POST /assignment/:assignmentId/startPickup
    return true
}

export const completeAssignment = async (assignmentId: string): Promise<boolean> => {
    // POST /assignment/:assignmentId/complete
    return true
}

export const scanAssignmentPackages = async (assignmentId: string, awbs: string[]): Promise<boolean> => {
    // POST /assignment/:assignmentId/scan
    return true
}

export const deliverSingleLMPackage = async (assignmentId: string, awb: string, picture: File): Promise<boolean> => {
    // POST /assignment/:assignmentId/deliver
    return true
}

export const pickupAssignmentPackages = async (assignmentId: string, awbs: string[]): Promise<boolean> => {
    // POST /assignment/:assignmentId/pickup
    return true
}

export const partialPickupAssignmentPackages = async (assignmentId: string, awbs: string[], reason: string): Promise<boolean> => {
    // POST /assignment/:assignmentId/pickupPartial
    return true
}