import {DriverForm} from '../../driver/components/DriverForm'
import {Modal, Stack} from 'react-bootstrap'
import React, {useState} from 'react'
import {LastMileAssignmentPackageSchema} from '../../driver/Model'
import Dropzone from 'react-dropzone'
import {ImageDropper} from './ImageDropper'

interface PhotoTakerProps {
    callback: (photo: File) => void;
    pkg: LastMileAssignmentPackageSchema;
}

export const PhotoTaker = ({pkg, callback}: PhotoTakerProps) => {
    const [chosenFile, setChosenFile] = useState<File | null>(null)

    const onDrop = (acceptedFiles: File[]) => {
        setChosenFile(acceptedFiles[0])
    }

    return (
        <Modal className="modal" show={true} size={"xl"}>
            <div className="modal-header justify-content-end border-0 pb-0">
            </div>

            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                <div className="mb-13 text-center">
                    <h1 className="mb-3">Delivery Confirmation</h1>
                </div>
                <Stack className={"mb-2"}>
                    <span>AWB: <b>{pkg.awb}</b></span>
                    <span><b>{pkg.recName}</b></span>
                    <span>{pkg.recAddr}, {pkg.recDistrict}, {pkg.recDistrict}, {pkg.recState} {pkg.recPostal}</span>

                    <div className="mt-10 mb-6 text-center">
                        <h3 className="fw-bold">Take a picture of delivery</h3>
                    </div>
                    {!chosenFile && <ImageDropper cb={onDrop}/>}
                    {chosenFile && <div className="text-center">
                        <img src={URL.createObjectURL(chosenFile)} className="img-fluid" alt={"preview"}/>
                    </div>}
                    <Stack direction={"horizontal"} style={{justifyContent: "space-around"}}>
                        <button className="btn btn-primary" onClick={() => chosenFile && callback(chosenFile)}
                                disabled={!chosenFile}>Submit
                        </button>
                        <button className="btn btn-danger" onClick={() => setChosenFile(null)}
                                disabled={!chosenFile}>Retake Picture
                        </button>
                    </Stack>
                </Stack>


            </div>
        </Modal>
    )

}