import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import React, {useState} from 'react'
import {FormSubmitCancelBtn} from './FormSubmitCancelBtn'
import {FormikProps} from 'formik/dist/types'


interface BaseCUFormProps<CreateSchema, UpdateSchema> {
    isUpdate: boolean
    callbackCreate: (data: CreateSchema) => void;
    callbackUpdate: (data: UpdateSchema) => void;
    callbackCancel: () => void;
    data?: UpdateSchema;
    initialValues: CreateSchema;
    createValidationSchema: Yup.ObjectSchema<CreateSchema>;
    updateValidationSchema: Yup.ObjectSchema<UpdateSchema>;
    children: (props: FormikProps<CreateSchema | UpdateSchema>) => React.ReactNode
}


export const BaseCUForm = <CreateSchema, UpdateSchema>({
                                                             isUpdate,
                                                             data: updateData,
                                                             callbackUpdate,
                                                             callbackCancel,
                                                             callbackCreate,
                                                             initialValues,
                                                             updateValidationSchema,
                                                             createValidationSchema,
                                                             children,
                                                         }: BaseCUFormProps<CreateSchema, UpdateSchema>) => {
    const [loading, setLoading] = useState<boolean>(false)

    const validationSchema = isUpdate ? updateValidationSchema : createValidationSchema
    const m_initialValues = (isUpdate && updateData) ? updateData : initialValues

    return (
        <Formik initialValues={m_initialValues} onSubmit={(data) => {
            setLoading(true)
            if (isUpdate) {
                // @ts-ignore Already checked by guarding if isUpdate
                callbackUpdate(data)
            } else {
                // @ts-ignore
                callbackCreate(data)
            }
            setLoading(false)
        }} validationSchema={validationSchema}>
            {(formikProps) => (
                <Form onSubmit={formikProps.handleSubmit}
                      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                    {formikProps.status && (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">{formikProps.status}</div>
                        </div>
                    )}

                    {children(formikProps)}

                    <FormSubmitCancelBtn isSubmitting={formikProps.isSubmitting} isValid={formikProps.isValid}
                                         loading={loading}
                                         callbackCancel={callbackCancel} />

                </Form>

            )}
        </Formik>
    )

}