import {useEffect, useState} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import {Spinner, Stack} from 'react-bootstrap'

type PackageActionProps = {onClose: () => void, code: string}

export function PackageAction({onClose, code}: PackageActionProps) {
    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
        onClose()
    }

    type PkgActionState = 'loading' | 'ok' | 'nok'

    useEffect(() => {
        setShow(true)
        setTimeout(() => {
            setLoaded(true)
            if (code.startsWith('KRMN')) setScanState('ok')
            else setScanState('nok')
        }, 4000)
    }, [])


    const [scanState, setScanState] = useState<PkgActionState>('loading')
    const [loaded, setLoaded] = useState(false)

    return (
        <>
            <Offcanvas show={show} backdrop={loaded ? true : 'static'} placement={'bottom'}
                       onHide={handleClose} style={{
                backgroundColor: scanState === 'loading' ? 'inherit' : (
                    scanState === 'ok' ? '#47be7d' : '#d9214e'
                ),
            }}>
                <Offcanvas.Header closeButton={loaded}>
                    <Offcanvas.Title>Processing</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Stack direction={'vertical'} gap={3} style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <b>AWB: {code}</b>
                        {!loaded &&
                            <Spinner animation="border" role="status" style={{width: '6rem', height: '6rem'}}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                        {loaded &&
                            <>
                                <h1 style={{color: 'white'}}>{scanState === 'ok' ? 'Success' : 'Failed'}</h1>
                                <p style={{color: 'white'}}>{scanState === 'ok' ? 'Package scanned successfully' : 'Package not found'}</p>
                                <button onClick={handleClose} className={'btn btn-primary'}>Close</button>
                            </>
                        }
                    </Stack>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
