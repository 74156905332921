import {useNavigate, useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'
import {PackageHeader} from './components/PackageHeader'
import {Button, Table} from 'react-bootstrap'
import {PackageScheme, PackageTrackingScheme} from './model'
import {getPackage, getPackageTracking} from './api'

export const PackageDetailPage = () => {
    const {pkgid} = useParams()

    const [driver, setDriver] = useState<PackageScheme | null>(null)
    const [packageTracking, setPackageTracking] = useState<PackageTrackingScheme[]>([])

    const navigate = useNavigate();
    const updateDriverData = async () => {
        try {
            const data = await getPackage(pkgid!)
            setDriver(data)
        } catch (e) {
            if (e instanceof Error && e.message === 'Not found') {
                navigate('/404', {replace: true})
            }
        }
    }

    const updateDriverAssignmentData = async () => {
        setPackageTracking(await getPackageTracking(pkgid!))
    }

    useEffect(() => {
        updateDriverData()
        updateDriverAssignmentData()
    }, [])

    if (!driver) return (<TopBarProgress />)

    return (
        <>
            <div className={'row mb-6'}>
                <div className={'col-lg-12'}>
                    <PackageHeader pkg={driver} />
                </div>
            </div>
            <div className={'row mb-6'}>
                <div className={'col-lg-12'}>
                    <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="fw-bold text-muted">
                            <th className="min-w-150px">Time</th>
                            <th className="min-w-120px">Location</th>
                            <th className="min-w-120px">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {packageTracking.map((assg, index) => (
                            <tr key={index} className={"cursor-pointer"} style={{cursor: "pointer !important"}}>
                                <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {assg.timestamp}
                                </span>
                                </td>
                                <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assg.location}
                                </span>
                                </td>
                                <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assg.description}
                                </span>
                                </td>
                            </tr>
                        ))}
                        <>
                        </>

                        </tbody>
                    </Table>
                </div>
            </div>

        </>
    )
}