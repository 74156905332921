import {Scanner} from './components/Scanner'
import {PackageAction} from './components/PackageAction'
import {useState} from 'react'

export const ScanPage = ({}) => {
    const [foundCode, setFoundCode] = useState(false)
    const [awbCode, setAwbCode] = useState<string>("")
    const sessionFoundCodes = new Set<string>();
    const [gUnpauseCb, setGUnpauseCb] = useState(() => () => {})
    const handleFound = (code: string, unpauseCb: () => void) => {
        setAwbCode(code)
        setFoundCode(true)
        setGUnpauseCb(() => unpauseCb);
    }

    const handleDone = () => {
        sessionFoundCodes.add(awbCode)
        setFoundCode(false)
        setAwbCode("")
        gUnpauseCb();
    }

    return (<>
        <Scanner callback={handleFound} pauseOnFound={true} fps={10} />
        {foundCode && <PackageAction code={awbCode} onClose={handleDone} />}
    </>)
}