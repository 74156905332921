/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {Modal, Stack} from 'react-bootstrap'
import {UnpackedPackageCreateScheme} from '../../model'
import {calculatePrice} from '../../services/CalculatePrice'

interface VerifyModalProps {
    callback: (cont: boolean) => void,
    pkgs: UnpackedPackageCreateScheme[]
}

export const VerifyModal: FC<VerifyModalProps> = ({callback, pkgs}) => {
    const [loading, setLoading] = useState(false)

    const calculateTotalPrice = (pkgs: UnpackedPackageCreateScheme[]) => {
        return pkgs.reduce((acc, curr) => {
            return acc + calculatePrice(curr)
        }, 0)
    }

    return (
        <Modal className="modal" show={true}>
            <div className="modal-header justify-content-end border-0 pb-0">
            </div>

            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                <div className="mb-13 text-center">
                    <h1 className="mb-3">Verify Package Price</h1>
                </div>

                <Stack>
                    <div className="mb-3">
                        <span>Package Count: {pkgs.length}</span>
                    </div>

                    <div className="mb-3">
                        <span>Total Price</span>
                        <span>RP. {calculateTotalPrice(pkgs) || '-'}</span>
                    </div>
                </Stack>

                <div className="d-flex flex-center justify-content-around flex-row-fluid pt-12">

                    <button type="button" onClick={() => callback(true)} className="btn btn-primary" disabled={loading}>
                        {!loading && 'Submit'}
                        {loading && (
                            <span className="indicator-progress" style={{display: 'block'}}>
                        Please wait...{' '}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                        )}
                    </button>
                    <button type="button" onClick={() => callback(false)} className="btn btn-danger" disabled={loading}>
                        cancel
                    </button>
                </div>
            </div>
        </Modal>
    )
}

