import {BaseScanner} from './components/BaseScanner'
// import {PackageAction} from './components/PackageAction'
import {MutableRefObject, useEffect, useRef, useState} from 'react'
import {ChecklistScannerScanNotif} from './components/ChecklistScannerScanNotif'
import {AssignmentPackageSchema, LastMileAssignmentPackageSchema} from '../driver/Model'
import {PhotoTaker} from './components/PhotoTaker'

export interface ChecklistScannerProps {
    packages: LastMileAssignmentPackageSchema[];
    finishCallback: (complete: boolean) => void;
    packageFoundCallback: (awb: string, picture: File) => Promise<boolean>;
    controlRef?: MutableRefObject<ChecklistScannerController | null>;
}

export interface ChecklistScannerController {
    getScannedPackages: () => string[];
}

export const PicProofScanner = ({packages, finishCallback, controlRef, packageFoundCallback}: ChecklistScannerProps) => {
    const [foundCode, setFoundCode] = useState(false)
    const [scannedPkg, setScannedPkg] = useState<LastMileAssignmentPackageSchema|null>();
    const [scannedAwb, setScannedAwb] = useState("")
    const packageFound = useRef(new Set<string>())
    const packageLeft = useRef(new Set<string>())
    const [gUnpauseCb, setGUnpauseCb] = useState(() => () => {
    })
    const [awbOk, setAwbOk] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>('')

    useEffect(() => {
        packageLeft.current = new Set(packages.filter(pkg => pkg.status === 'delivering').map(p => p.awb))
        if (controlRef) {
            controlRef.current = {
                getScannedPackages: () => Array.from(packageFound.current),
            }
        }
    }, [])


    const handleFound = (code: string, unpauseCb: () => void) => {
        setScannedAwb(code);
        if (packageLeft.current.has(code)) {
            setScannedPkg(packages.find(p => p.awb === code) as LastMileAssignmentPackageSchema)
            packageLeft.current.delete(code)
            packageFound.current.add(code)
            setAwbOk(true)
            setErrorMsg('')
        } else {
            setAwbOk(false)
            if (packageFound.current.has(code)) {
                setErrorMsg('Package already scanned')
            } else {
                setErrorMsg('Package not found or already scanned previously')
            }
        }
        setGUnpauseCb(() => unpauseCb)
        setFoundCode(true)
    }

    const handleClose = () => {
        setFoundCode(false)
        setErrorMsg('')
        setScannedAwb('')
        setScannedPkg(null)

        if (packageLeft.current.size === 0) {
            finishCallback(true)
        }

        gUnpauseCb()

    }

    const handleCloseNotif = async (photo: File) => {
        await packageFoundCallback(scannedAwb, photo);
        handleClose()
    }

    return (<>
        <BaseScanner callback={handleFound} pauseOnFound={true} fps={10} />
        {foundCode && scannedPkg &&
            <PhotoTaker callback={handleCloseNotif} pkg={scannedPkg} />}
        {!awbOk && foundCode &&
            <ChecklistScannerScanNotif code={scannedAwb} onClose={handleClose} success={false} errorMsg={errorMsg} />}
    </>)
}