import {Html5QrcodeScannerConfig} from 'html5-qrcode/html5-qrcode-scanner'
import {useEffect, useId, useState} from 'react'
import {CameraDevice, Html5Qrcode} from 'html5-qrcode'
import {Button, FormSelect, Stack} from 'react-bootstrap'
import {useMobileDetect} from '../../../utils/mobileCheck'


type ScannerProps = {callback: (decodedText: string, unPauseCb: () => void) => void, pauseOnFound?: boolean} & Html5QrcodeScannerConfig
export const Scanner = ({callback, pauseOnFound=true, ...html5QRConfig}: ScannerProps) => {

    const [isScanning, setIsScanning] = useState(false)
    const [errorMsg, setErrorMsg] = useState<null | string>('Waiting')
    const [cameras, setCameras] = useState<CameraDevice[]>([])
    const camViewId = useId()
    const [selectedCamId, setSelectedCamId] = useState<string>('')

    const deviceCheck = useMobileDetect()
    const [scanner, setScanner] = useState<null | Html5Qrcode>(null)

    useEffect(() => {
        const html5QrCode = new Html5Qrcode(camViewId)
        setScanner(html5QrCode)
        return () => {
            if (isScanning) {
                try {
                    html5QrCode.stop().catch(console.error)
                } catch (e) {

                }
            }
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (selectedCamId === '' || !isScanning) return;
            if (scanner?.isScanning) await scanner?.stop();
            await startScanning(selectedCamId)
        })()
    }, [selectedCamId])

    const processCode = (decodedText: string, decodedResult: object) => {
        console.log(decodedText, decodedResult)
        if (!pauseOnFound) {
            callback(decodedText, () => {})
        } else {
            scanner?.pause(true);
            callback(decodedText, () => {
                scanner?.resume()
            })
        }

    }

    const startScanning = async (selectedCam: string) => {
        console.log(`Starting scanning with ${selectedCamId}`)
        if (deviceCheck.isMobile()) {
            await scanner?.start({facingMode: 'environment'}, html5QRConfig, processCode, undefined)
        } else if (selectedCam) {
            await scanner?.start(selectedCam, html5QRConfig, processCode, undefined)
        }
        setIsScanning(true)
    }

    const stopScanning = async () => {
        await scanner?.stop()
        setIsScanning(false)
    }
    const initCam = async () => {
        setIsScanning(true)
        if (!selectedCamId) {
            try {
                const cameras = await Html5Qrcode.getCameras()
                if (cameras.length == 0) {
                    setErrorMsg('No cameras found')
                    return
                }
                setSelectedCamId(cameras[0].id)
                setCameras(cameras)
            } catch (e) {
                console.error(e)
            }
        } else {
            await startScanning(selectedCamId)
        }
    }

    return (
        <Stack direction={'vertical'}>
            <div id={camViewId} style={{width: '100%'}}></div>
            {cameras && cameras.length > 1 &&
                <FormSelect onChange={(e) => e.target.value && setSelectedCamId(e.target.value)} value={selectedCamId}>
                    {cameras.map((cam) => <option key={cam.id} value={cam.id}>{cam.label}</option>)}
                </FormSelect>}
            {!isScanning && <Button onClick={initCam}>Start Scanning</Button>}
            {isScanning && <Button onClick={stopScanning}>Stop Scanning</Button>}
        </Stack>
    )
}