import {PackageScheme} from './model'

export const packagesSample: PackageScheme[] = [
    {
        "senName": "John Doe",
        "senPhone": "1234567890",
        "senAddr": "123 Main St",
        "senPostal": "12345",
        "senCity": "City",
        "senState": "State",
        "senRegency": "Regency",
        "senDistrict": "District",
        "recName": "Jane Doe",
        "recPhone": "0987654321",
        "recAddr": "456 Secondary St",
        "recPostal": "54321",
        "recCity": "City",
        "recState": "State",
        "recRegency": "Regency",
        "recDistrict": "District",
        "weight": 1,
        "serviceType": "Express",
        "insurance": true,
        "fragile": false,
        "dimension": {
            "length": 10,
            "width": 10,
            "height": 10
        },
        "packageValue": 100,
        "price": 50,
        "id": "1",
        "awb": "AWB123",
        "status": "Delivered",
        "createdAt": "2023-01-01T00:00:00Z"
    },
    {
        "senName": "Alice",
        "senPhone": "1234567890",
        "senAddr": "123 Main St",
        "senPostal": "12345",
        "senCity": "City",
        "senState": "State",
        "senRegency": "Regency",
        "senDistrict": "District",
        "recName": "Bob",
        "recPhone": "0987654321",
        "recAddr": "456 Secondary St",
        "recPostal": "54321",
        "recCity": "City",
        "recState": "State",
        "recRegency": "Regency",
        "recDistrict": "District",
        "weight": 2,
        "serviceType": "Standard",
        "insurance": false,
        "fragile": true,
        "dimension": {
            "length": 20,
            "width": 20,
            "height": 20
        },
        "packageValue": 200,
        "price": 100,
        "id": "2",
        "awb": "AWB456",
        "status": "In Transit",
        "createdAt": "2023-02-01T00:00:00Z"
    },
    {
        "senName": "Charlie",
        "senPhone": "1234567890",
        "senAddr": "123 Main St",
        "senPostal": "12345",
        "senCity": "City",
        "senState": "State",
        "senRegency": "Regency",
        "senDistrict": "District",
        "recName": "Dave",
        "recPhone": "0987654321",
        "recAddr": "456 Secondary St",
        "recPostal": "54321",
        "recCity": "City",
        "recState": "State",
        "recRegency": "Regency",
        "recDistrict": "District",
        "weight": 3,
        "serviceType": "Economy",
        "insurance": true,
        "fragile": true,
        "dimension": {
            "length": 30,
            "width": 30,
            "height": 30
        },
        "packageValue": 300,
        "price": 150,
        "id": "3",
        "awb": "AWB789",
        "status": "Pending",
        "createdAt": "2023-03-01T00:00:00Z"
    },
    {
        "senName": "Eve",
        "senPhone": "1234567890",
        "senAddr": "123 Main St",
        "senPostal": "12345",
        "senCity": "City",
        "senState": "State",
        "senRegency": "Regency",
        "senDistrict": "District",
        "recName": "Frank",
        "recPhone": "0987654321",
        "recAddr": "456 Secondary St",
        "recPostal": "54321",
        "recCity": "City",
        "recState": "State",
        "recRegency": "Regency",
        "recDistrict": "District",
        "weight": 4,
        "serviceType": "Express",
        "insurance": false,
        "fragile": false,
        "dimension": {
            "length": 40,
            "width": 40,
            "height": 40
        },
        "packageValue": 400,
        "price": 200,
        "id": "4",
        "awb": "AWB012",
        "status": "Delivered",
        "createdAt": "2023-04-01T00:00:00Z"
    },
    {
        "senName": "Grace",
        "senPhone": "1234567890",
        "senAddr": "123 Main St",
        "senPostal": "12345",
        "senCity": "City",
        "senState": "State",
        "senRegency": "Regency",
        "senDistrict": "District",
        "recName": "Harry",
        "recPhone": "0987654321",
        "recAddr": "456 Secondary St",
        "recPostal": "54321",
        "recCity": "City",
        "recState": "State",
        "recRegency": "Regency",
        "recDistrict": "District",
        "weight": 5,
        "serviceType": "Standard",
        "insurance": true,
        "fragile": true,
        "dimension": {
            "length": 50,
            "width": 50,
            "height": 50
        },
        "packageValue": 500,
        "price": 250,
        "id": "5",
        "awb": "AWB345",
        "status": "In Transit",
        "createdAt": "2023-05-01T00:00:00Z"
    }
]