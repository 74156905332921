import {HubCreateScheme, HubDetailScheme} from '../Model'
import * as Yup from 'yup'
import React, {useState} from 'react'
import {BaseRowField} from '../../commons/components/BaseRowField'
import {BaseColField} from '../../commons/components/BaseColField'
import {BaseCUForm} from '../../commons/components/BaseCUForm'
import {createHub, updateHub} from '../Api'

const initialValue: HubCreateScheme = {
    name: '',
    email: '',
    phone: '',
    addr: '',
    city: '',
    state: '',
    regency: '',
    district: '',
    postal: '',
    password: '',
    confirmPassword: '',
}

const createValidationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
    addr: Yup.string().required().label('Address'),
    city: Yup.string().required().label('City'),
    state: Yup.string().required().label('State'),
    regency: Yup.string().required().label('Regency'),
    district: Yup.string().required().label('District'),
    postal: Yup.string().required().label('Postal Code'),
    password: Yup.string().required().label('Password'),
    confirmPassword: Yup.string().required().oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

const updateValidationSchema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
    addr: Yup.string().required().label('Address'),
    city: Yup.string().required().label('City'),
    state: Yup.string().required().label('State'),
    regency: Yup.string().required().label('Regency'),
    district: Yup.string().required().label('District'),
    postalCode: Yup.string().required().label('Postal Code'),
    postal: Yup.string().required().label('Postal Code'),

})

interface BaseHubFormProps {
    isUpdate: boolean
    callbackCreate: (data: HubDetailScheme|false) => void;
    callbackUpdate: (data: HubDetailScheme|false) => void;
    callbackCancel: () => void;
}

interface HubUpdateFormProps extends BaseHubFormProps {
    isUpdate: true,
    data: HubDetailScheme
}

interface HubCreateFormProps extends BaseHubFormProps {
    isUpdate: false,
    data?: HubDetailScheme

}

export type HubFormProps = HubCreateFormProps | HubUpdateFormProps

export const HubForm = ({
                               isUpdate,
                               data: updateData,
                               callbackUpdate,
                               callbackCancel,
                               callbackCreate,
                           }: HubFormProps) => {
    const updateCb = async (data: HubDetailScheme) => {
        try {
            const res = await updateHub(data)
            callbackUpdate(res)
        } catch (e) {
            callbackUpdate(false)
        }
    }

    const createCb = async (data: HubCreateScheme) => {
        try {
            const res = await createHub(data)
            callbackCreate(res)
        } catch (e) {
            callbackCreate(false)
        }
    }
    return (
        <BaseCUForm<HubCreateScheme, HubDetailScheme> isUpdate={isUpdate} callbackCreate={createCb}
                                                      callbackUpdate={updateCb}
                                                      callbackCancel={callbackCancel} initialValues={initialValue}
                                                      data={updateData}
                                                      createValidationSchema={createValidationSchema}
                                                      updateValidationSchema={updateValidationSchema}>
            {({touched, errors}) => (
                <>
                    <BaseRowField touched={touched} errors={errors} name={'name'} label={'Hub Name'} />
                    <BaseRowField touched={touched} errors={errors} name={'email'} label={'Hub Email'} />
                    {!isUpdate && (<>
                        <BaseRowField touched={touched} errors={errors} name={'password'} label={'Hub Password'}
                                      type={'password'} />
                        <BaseRowField touched={touched} errors={errors} name={'confirmPassword'}
                                      label={'Confirm Password'} type={'password'} />
                    </>)}

                    <BaseRowField touched={touched} errors={errors} name={'phone'} label={'Hub Phone'} />

                    <div className="row mb-8">
                        <label className="col-lg-2 col-form-label required fw-bolder text-dark fs-6">Address</label>
                        <div className={'col-lg-10'}>
                            <div className={'row mb-6'}>
                                <BaseColField label={'Hub State'} touched={touched} errors={errors}
                                              name={'state'} gridSize={6} />
                                <BaseColField label={'Hub City'} touched={touched} errors={errors}
                                              name={'city'} gridSize={6} />
                            </div>
                            <div className={'row mb-6'}>
                                <BaseColField label={'Hub Regency'} touched={touched} errors={errors}
                                              name={'regency'} gridSize={6} />
                                <BaseColField label={'Hub District'} touched={touched} errors={errors}
                                              name={'district'} gridSize={6} />
                            </div>
                            <div className={'row'}>
                                <BaseColField label={'Hub Postal Code'} touched={touched} errors={errors}
                                              name={'postal'} gridSize={6} />
                            </div>
                            <div className={'row'}>
                                <BaseColField label={'Hub Address'} touched={touched} errors={errors}
                                              name={'addr'} gridSize={12} type={'textarea'} />
                            </div>
                        </div>
                    </div>

                </>
            )}
        </BaseCUForm>
    )
}