import {KTIcon} from '../../../../_metronic/helpers'
import {PackageScheme} from '../model'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {AWBPrinter} from './CreatePackage/AWBPrinter'

export const PackageHeader = ({pkg}: {pkg: PackageScheme}) => {
    const navigate = useNavigate()

    const refreshPage = () => {
        navigate(0)
    }


    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                            {pkg.awb}
                                        </a>
                                    </div>

                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                        >
                                            <KTIcon iconName="profile-circle" className="fs-4 me-1" />
                                            Sender: {pkg.senName} - {pkg.senPhone}
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                        >
                                            <KTIcon iconName="sms" className="fs-4 me-1" />
                                            Receiver: {pkg.recName} - {pkg.recPhone}
                                        </a>
                                    </div>
                                </div>

                                <div className="d-flex my-4">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-primary me-3"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_offer_a_deal"
                                        onClick={() => {AWBPrinter([pkg])}}
                                    >
                                        Print AWB
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}