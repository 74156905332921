import {PackageScheme, PackageTrackingScheme, UnpackedPackageCreateScheme} from './model'

export const createPackages = async (data: UnpackedPackageCreateScheme[]): Promise<PackageScheme[]> => {
    const result: PackageScheme[] = []
    for (const pkg of data) {
        result.push({
            ...pkg,
            id: '1',
            price: 50,
            awb: 'AWB' + (Math.ceil(Math.random() * 10000)).toString(),
            status: 'Processed',
            createdAt: '2023-01-01T00:00:00Z',
        })
    }
    return result
}

export const getPackageTracking = async (id: string): Promise<PackageTrackingScheme[]> => {
    return [{
        timestamp: '2023-01-01T00:00:00Z',
        location: 'Hub 1',
        description: 'Pickedup by driver'
    }]
}

export const getPackage = async (id: string): Promise<PackageScheme> => {
    return {
        id: '1',
        senName: 'Sender',
        senPhone: '08123456789',
        senAddr: 'Jalan Jalan',
        senPostal: '12345',
        senCity: 'City',
        senState: 'State',
        senRegency: 'Regency',
        senDistrict: 'District',
        recName: 'Receiver',
        recPhone: '08123456789',
        recAddr: 'Jalan Jalan',
        recPostal: '12345',
        recCity: 'City',
        recState: 'State',
        recRegency: 'Regency',
        recDistrict: 'District',
        weight: 1,
        serviceType: 'Regular',
        insurance: false,
        fragile: false,
        dimension: {length: 10, width: 10, height: 10},
        packageValue: 100000,
        price: 50,
        awb: 'AWB1234',
        status: 'Processed',
        createdAt: '2023-01-01T00:00:00Z',
    }
}

export const listPackages = async (): Promise<PackageScheme[]> => {
    return [
        {
            id: '1',
            senName: 'Sender',
            senPhone: '08123456789',
            senAddr: 'Jalan Jalan',
            senPostal: '12345',
            senCity: 'City',
            senState: 'State',
            senRegency: 'Regency',
            senDistrict: 'District',
            recName: 'Receiver',
            recPhone: '08123456789',
            recAddr: 'Jalan Jalan',
            recPostal: '12345',
            recCity: 'City',
            recState: 'State',
            recRegency: 'Regency',
            recDistrict: 'District',
            weight: 1,
            serviceType: 'Regular',
            insurance: false,
            fragile: false,
            dimension: {length: 10, width: 10, height: 10},
            packageValue: 100000,
            price: 50,
            awb: 'AWB1234',
            status: 'Processed',
            createdAt: '2023-01-01T00:00:00Z',
        },
    ]
}