import {BaseScanner} from './components/BaseScanner'
// import {PackageAction} from './components/PackageAction'
import {MutableRefObject, useEffect, useRef, useState} from 'react'
import {ChecklistScannerScanNotif} from './components/ChecklistScannerScanNotif'
import {AssignmentPackageSchema} from '../driver/Model'

export interface ChecklistScannerProps {
    packages: AssignmentPackageSchema[];
    finishCallback: (complete: boolean) => void;
    controlRef?: MutableRefObject<ChecklistScannerController | null>;
}

export interface ChecklistScannerController {
    getScannedPackages: () => string[];
}

export const ChecklistScanner = ({packages, finishCallback, controlRef}: ChecklistScannerProps) => {
    const [foundCode, setFoundCode] = useState(false)
    const [awbCode, setAwbCode] = useState<string>('')
    const packageFound = useRef(new Set<string>())
    const packageLeft = useRef(new Set<string>())
    const [gUnpauseCb, setGUnpauseCb] = useState(() => () => {
    })
    const [awbOk, setAwbOk] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>('')

    useEffect(() => {
        packageLeft.current = new Set(packages.filter(pkg => pkg.status === 'pending').map(p => p.awb))
        if (controlRef) {
            controlRef.current = {
                getScannedPackages: () => Array.from(packageFound.current),
            }
        }
    }, [])


    const handleFound = (code: string, unpauseCb: () => void) => {
        setAwbCode(code)
        if (packageLeft.current.has(code)) {
            packageLeft.current.delete(code)
            packageFound.current.add(code)
            setAwbOk(true)
            setErrorMsg('')
        } else {
            setAwbOk(false)
            if (packageFound.current.has(code)) {
                setErrorMsg('Package already scanned')
            } else {
                setErrorMsg('Package not found or already scanned previously')
            }
        }
        setGUnpauseCb(() => unpauseCb)
        setFoundCode(true)
    }

    const handleDone = () => {
        setFoundCode(false)
        setErrorMsg('')
        setAwbCode('')

        if (packageLeft.current.size === 0) {
            finishCallback(true)
        }

        gUnpauseCb()
    }

    return (<>
        <BaseScanner callback={handleFound} pauseOnFound={true} fps={10} />
        {foundCode &&
            <ChecklistScannerScanNotif code={awbCode} onClose={handleDone} success={awbOk} errorMsg={errorMsg} />}
    </>)
}