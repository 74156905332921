/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { ContestTable } from "./component/ContestTable";
import { SubmissionsTable } from "./component/SubmissionTable";
import {PackageList} from '../../modules/package/components/PackageList'

const DashboardPage: FC = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
          <PackageList/>
        {/*<ContestTable className='card-xxl-stretch mb-5 mb-xl-8' />*/}
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
