import {PackageScheme} from '../../model'
import QRCode from 'qrcode'

interface PackageSchemeImg extends PackageScheme{
    qr: string
}

const singleCellAwb = (data: PackageSchemeImg) => {
    return `
    <div class="tableCont">
            <table>
            <thead>
            <tr>
                <th colSpan="3">KLIR&nbsp;&nbsp;&nbsp;LOGISTIK</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>AWB:</td>
                <td>${data.awb}</td>
                <td rowSpan="12"><img src="${data.qr}" alt="Qr code"/></td>
            </tr>
            <tr>
                <td rowSpan="5">Pengirim:</td>
                <td>${data.senName}</td>
            </tr>
            <tr>
                <td>${data.senAddr}</td>
            </tr>
            <tr>
                <td>${data.senPhone}</td>
            </tr>
            <tr>
                <td>${data.senDistrict}, ${data.senRegency}</td>
            </tr>
            <tr>
                <td>${data.senCity}, ${data.senState} ${data.senPostal}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="5">Penerima:</td>
                <td>${data.recName}</td>
            </tr>
            <tr>
                <td>${data.recPhone}</td>
            </tr>
            <tr>
                <td>${data.recAddr}</td>
            </tr>
            <tr>
                <td>${data.recDistrict}, ${data.recRegency}</td>
            </tr>
            <tr>
                <td>${data.recCity}, ${data.recState} ${data.recPostal}</td>
            </tr>
            </tbody>
        </table>

</div>
    `
}

const printFrame = (content: string) => `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Print Frame</title>
  <style>
          body {
              margin-left: 5px;
              margin-right: 5px;
              padding: 0;
          }

          .pageBreak {
              page-break-after: always;
          }
          table {
            width:100%;
            align-items: start;
          }
            table { page-break-after:auto }
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group }

          
          td {
          vertical-align: top;
          }
          
          .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            column-gap: 10px;
            row-gap: 20px;
          }
          
          .tableCont {
            width: 95%;
          }
      
  </style>
</head>
<body>
<div class="container">
${content}
</div>
<script type="application/javascript">
      setTimeout(() => {
          window.print();
      }, 1000);
  </script>
</body>
</html>
`;


export const AWBPrinter = async (data: PackageScheme[]) => {
    let content = '';
    const addedQr = [];
    for (const pkg of data) {
        const qr = await QRCode.toDataURL(pkg.awb, {
            width: 100, margin: 5
        });
        addedQr.push({...pkg, qr});
    }
    for (const addedQrElement of addedQr) {
        content += singleCellAwb(addedQrElement)
    }

    const fullPageHtml = printFrame(content);
    console.log(fullPageHtml)
    const printWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (!printWindow) {
        throw new Error('Failed to open print window');
    }
    printWindow.document.write(fullPageHtml);
}