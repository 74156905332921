import React, {HTMLInputTypeAttribute} from 'react'
import {ErrorMessage, Field, getIn} from 'formik'
import clsx from 'clsx'

interface BasicFieldProp {
    touched: {[key: string]: any},
    errors: {[key: string]: any},
    name: string,
    type?: HTMLInputTypeAttribute,
    label: string,
    gridSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    className?: string
    disabled?: boolean,
    options?: {value: string, label: string}[]
}


interface SelectFieldProps extends BasicFieldProp {
    touched: {[key: string]: any},
    errors: {[key: string]: any},
    name: string,
    label: string,
    gridSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    className?: string
    disabled?: boolean,
    type: 'select',
    options: {value: string, label: string}[]
}

type CreatePackageFieldProps = (BasicFieldProp | SelectFieldProps)

export const BaseColField = ({
                                 touched,
                                 errors,
                                 name,
                                 type = 'text',
                                 label,
                                 gridSize = 4,
                                 className,
                                 disabled = false,
                                 options,
                             }: CreatePackageFieldProps) => {
    return (
        <div className={`col-lg-${gridSize}` + (className ? ' ' + className : '')}>
            <label htmlFor={name} className="form-label fw-bolder text-dark fs-6 required">{label}</label>
            {type === 'select' ?
                <Field name={name} as={'select'} disabled={disabled}
                       className={clsx(
                           'form-control bg-transparent',
                           {
                               'is-invalid': getIn(touched, name) && getIn(errors, name),
                           },
                           {
                               'is-valid': getIn(touched, name) && !getIn(errors, name),
                           })}>
                    {options?.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </Field>
                :
                <Field name={name} type={type} {...(type === 'textarea' ? {as: 'textarea'} : {})} disabled={disabled}
                       className={clsx(
                           'form-control bg-transparent',
                           {
                               'is-invalid': getIn(touched, name) && getIn(errors, name),
                           },
                           {
                               'is-valid': getIn(touched, name) && !getIn(errors, name),
                           })} />
            }
            <ErrorMessage name={name} component={'div'} className={'fv-plugins-message-container'}>
                {(errorMessage) => (
                    <div className="fv-help-block">
                        <span role="alert">{errorMessage}</span>
                    </div>
                )}
            </ErrorMessage>
        </div>
    )
}