import {DriverAssignmentScheme} from '../Model'
import {useNavigate} from 'react-router-dom'
import {completeAssignment, startAssignment, startPickupAssignment} from '../Api'

export const DriverAssignmentButton = ({assg}: {assg: DriverAssignmentScheme}) => {
    const navigate = useNavigate()

    switch (assg.status) {
        case 'assigned':
            return (
                <button className="btn btn-primary btn-lg" type="button" onClick={async () => {
                    await startAssignment(assg.id);
                    navigate(0)
                }}> Start Assignment </button>
            )
        case 'pickingUp':
            return (
                <button className="btn btn-primary btn-lg" type="button" onClick={async () => {
                    navigate(`/assignment/${assg.id}/scanner`)
                }}> Scan Package </button>
            )
        case 'enrouteStart':
            return (
                <button className="btn btn-primary btn-lg" type="button" onClick={async () => {
                    await startPickupAssignment(assg.id);
                    navigate(`/assignment/${assg.id}/scanner`)
                }}> Start Scanning </button>
            )
        case 'enrouteDest':
            return (
                <button className="btn btn-primary btn-lg" type="button" onClick={async () => {
                    await completeAssignment(assg.id);
                    navigate(0)
                }}> Complete Assignment </button>
            )
        default:
            return (
                <button className="btn btn-primary btn-lg" disabled type="button"> NA </button>
            )
    }
}