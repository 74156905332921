/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createRef, useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {ContestModel, useAuth} from '../../../modules/auth'
import {getContests, submitContest} from '../../../modules/auth/core/_requests'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import {Button, Card, Table} from 'react-bootstrap'
import {Dropdown1} from '../../../../_metronic/partials/dropdown/Dropdown1'
import {useNavigate} from 'react-router-dom'
import {AWBPrinter} from './CreatePackage/AWBPrinter'
import {packagesSample} from '../sampleData'
import {PackageGistScheme} from '../model'
import {listPackages} from '../api'


export const PackageList: React.FC<{}> = ({}) => {
    const [packageList, setPackageList] = useState<PackageGistScheme[]>([])

    const navigate = useNavigate()

    const updatePackages = async () => {
        setPackageList(await listPackages());
    }


    useEffect(() => {
        updatePackages()
    }, [])

    return (
        <div className={'card card-xl-stretch mb-xl-8'}>
            <div className={'card-header border-0 pt-5'}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Package List</span>
                </h3>
                <div className="card-toolbar">
                    <Button variant={'primary'} onClick={() => {
                        navigate('/package/create')
                    }}>Create<KTIcon iconName="document" className="btn-icon fs-2 m-0" /></Button>
                </div>
                <div className="card-toolbar">
                    <Button variant={'primary'} onClick={() => {
                        AWBPrinter(packagesSample);
                    }}>Test Print<KTIcon iconName="document" className="btn-icon fs-2 m-0" /></Button>
                </div>
            </div>


            <div className={'card-body py-3'}>
                <Card.Title>Package List</Card.Title>
                <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bold text-muted">
                        <th className="min-w-150px">AWB</th>
                        <th className="min-w-120px">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {packageList.map(pkg => (
                        <tr onClick={() => {
                            navigate(`/package/${pkg.awb}`)
                        }}>
                            <td>
                                    <span
                                        className="text-dark fw-bold text-hover-primary d-block fs-6">{pkg.awb}</span>
                            </td>
                            <td>
                                    <span
                                        className="text-muted fw-bold text-hover-primary d-block fs-6">{pkg.status}</span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>


        </div>
    )
}

