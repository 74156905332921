import {DriverAssignmentPackageItemStatus, DriverAssignmentScheme, DriverAssignmentStatus} from '../Model'
import {KTIcon} from '../../../../_metronic/helpers'
import {Table} from 'react-bootstrap'
import React from 'react'
import {DriverAssignmentButton} from './DriverAssingmentButton'

export const DriverAssignmentCard = ({assg}: {assg: DriverAssignmentScheme}) => {
    return (
        <>
            <div className={'row'}>
                <div className={'col-md-8 col-12'}>
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex align-items-center mb-2">
                                                <a href="#"
                                                   className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                                    Assignment #{assg.id} - Status: {assg.status}
                                                </a>
                                            </div>

                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                >
                                                    <KTIcon iconName="profile-circle" className="fs-4 me-1" />
                                                    Driver #{assg.driverId}
                                                </a>
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                >
                                                    <KTIcon iconName="time" className="fs-4 me-1" />
                                                    {assg.startTime || `${assg.plannedStartTime}~`} {assg.endTime ? ` - ${assg.endTime}` : ` +- ${assg.plannedDurationMinute} min`}
                                                </a>
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                                >
                                                    <KTIcon iconName="sms" className="fs-4 me-1" />
                                                    {assg.type}
                                                </a>
                                            </div>
                                        </div>

                                        <div className="d-flex my-4 d-none d-md-flex">
                                            <DriverAssignmentButton assg={assg}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-md-4 col-12'}>
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex align-items-center mb-2">
                                                <a href="#"
                                                   className="text-gray-800 fs-2 fw-bolder me-1">
                                                    Package count: {assg.packages.length}
                                                </a>
                                            </div>

                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                {Object.entries(assg.packages.reduce((acc, pkg) => {
                                                    acc[pkg.status] = (acc[pkg.status] || 0) + 1
                                                    return acc
                                                }, {} as Record<DriverAssignmentPackageItemStatus, number>)).map(([status, count]) => (
                                                    <a
                                                        href="#"
                                                        className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                    >
                                                        {status}: {count}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12' + (assg.type !== 'lastmile' ? ' col-md-6' : '')}>
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex align-items-center mb-2">
                                                <a href="#"
                                                   className="text-gray-800 fs-2 fw-bolder me-1">
                                                    Pickup Place : {assg.startName} -
                                                    ({assg.type === 'merctohub' ? 'Merchant' : 'Hub'})
                                                </a>
                                            </div>

                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                <span
                                                    className="d-flex align-items-center text-gray-800 text-hover-primary me-5 mb-2"
                                                >
                                                    {assg.startAddr}, {assg.startDistrict}, {assg.startRegency}, {assg.startCity}, {assg.startState} {assg.startPostal}
                                                </span>
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                >
                                                    Phone: {assg.startPhone}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {assg.type !== 'lastmile' && <div className={'col-12 col-md-6'}>
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex align-items-center mb-2">
                                                <a href="#"
                                                   className="text-gray-800 fs-2 fw-bolder me-1">
                                                    Destination Place : {assg.destName} - (Hub)
                                                </a>
                                            </div>

                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                <span
                                                    className="d-flex align-items-center text-gray-800 text-hover-primary me-5 mb-2"
                                                >
                                                    {assg.destAddr}, {assg.destDistrict}, {assg.destRegency}, {assg.destCity}, {assg.destState} {assg.destPostal}
                                                </span>
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                >
                                                    Phone: {assg.destPhone}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Packages</span>
                            </h3>
                        </div>

                        <div className="card-body pt-1 pb-0">
                            <Table responsive
                                   className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                <tr className="fw-bold text-muted">
                                    <th className="min-w-150px">Package Id</th>
                                    <th className="min-w-150px">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {assg.packages.map(pkg => (
                                    <tr key={pkg.awb}>
                                        <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {pkg.awb}
                                </span>
                                        </td>
                                        <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {pkg.status}
                                </span>
                                        </td>
                                    </tr>
                                ))}
                                <>
                                </>

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}