import {useNavigate, useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {BriefDriverAssignmentSchema, DriverDetailScheme} from './Model'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getDriver, getDriverAssignment} from './Api'
import {DriverHeader} from './components/DriverHeader'
import {Button, Table} from 'react-bootstrap'

export const DriverDetail = () => {
    const {driverid} = useParams()

    const [driver, setDriver] = useState<DriverDetailScheme | null>(null)
    const [driverAssignments, setDriverAssignments] = useState<BriefDriverAssignmentSchema[]>([])

    const navigate = useNavigate();
    const updateDriverData = async () => {
        try {
            const data = await getDriver(driverid!)
            setDriver(data)
        } catch (e) {
            if (e instanceof Error && e.message === 'Not found') {
                navigate('/404', {replace: true})
            }
        }
    }

    const updateDriverAssignmentData = async () => {
        setDriverAssignments(await getDriverAssignment(driverid!))
    }

    useEffect(() => {
        updateDriverData()
        updateDriverAssignmentData()
    }, [])

    if (!driver) return (<TopBarProgress />)

    return (
        <>
            <div className={'row mb-6'}>
                <div className={'col-lg-12'}>
                    <DriverHeader driver={driver} />
                </div>
            </div>
            <div className={'row mb-6'}>
                <div className={'col-lg-12'}>
                    <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="fw-bold text-muted">
                            <th className="min-w-150px">Type</th>
                            <th className="min-w-120px">Date and Time</th>
                            <th className="min-w-120px">Status</th>
                            <th className="min-w-20px">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {driverAssignments.map(assg => (
                            <tr key={assg.id} className={"cursor-pointer"} style={{cursor: "pointer !important"}} onClick={() => {
                                navigate(`/assignment/${assg.id}`)
                            }}>
                                <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {assg.type}
                                </span>
                                </td>
                                <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assg.date}: {assg.startTime}
                                </span>
                                </td>
                                <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {assg.status}
                                </span>
                                </td>
                                <td>
                                    <Button variant={'outline-primary'} onClick={() => {
                                        // setSelectedDriver(assg)
                                        // setShowModal(true)
                                    }}>Detail</Button>
                                </td>
                            </tr>
                        ))}
                        <>
                        </>

                        </tbody>
                    </Table>
                </div>
            </div>

        </>
    )
}