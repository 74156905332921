import {
    DriverAssignmentScheme,
    DriverDetailScheme,
    InterHubAndMercToHubDriverAssignmentScheme,
    LastMileDriverAssignmentScheme,
} from './Model'

export const expDriver: DriverDetailScheme = {
    name: 'John Doe',
    email: 'john@doe.com',
    id: '1',
    phone: '08123456789',
}

export const expDriverAsg: InterHubAndMercToHubDriverAssignmentScheme = {
    plannedStartTime: '08:00',
    plannedDurationMinute: 60,
    date: '2021-11-11',
    driverId: '1',
    driverName: 'John Doe',
    id: '1',
    packages: [{
        awb: '1234567890',
        status: 'pending',
    },{
        awb: '1234567891',
        status: 'pending',
    },{
        awb: '1234567892',
        status: 'pending',
    },{
        awb: '1234567893',
        status: 'pickedup',
    },],
    startAddr: 'Jl. Jendral Sudirman No. 1',
    startCity: 'Jakarta',
    startDistrict: 'Selatan',
    startName: 'John Doe',
    startPhone: '08123456789',
    startPostal: '12345',
    startRegency: 'Jakarta',
    startState: 'DKI Jakarta',
    status: 'pickingUp',
    type: 'interhub',
    destAddr: 'Jl. Jendral Sudirman No. 1',
    destCity: 'Jakarta',
    destDistrict: 'Selatan',
    destName: 'John Doe',
    destPhone: '08123456789',
    destPostal: '12345',
    destRegency: 'Jakarta',
    destState: 'DKI Jakarta',
}

export const expLMDriverAsg: LastMileDriverAssignmentScheme = {
    plannedStartTime: '08:00',
    plannedDurationMinute: 60,
    date: '2021-11-11',
    driverId: '1',
    driverName: 'John Doe',
    id: '2',
    packages: [{
        awb: '1234567890',
        status: 'delivering',
        recAddr: "Jl. Jendral Sudirman No. ",
        recCity: 'Jakarta',
        recDistrict: 'Selatan',
        recName: 'John Doe',
        recPhone: '08123456789',
        recPostal: '12345',
        recRegency: 'Jakarta',
        recState: 'DKI Jakarta',
    }, {
        awb: '1234567891',
        status: 'delivered',
        recAddr: "Jl. Jendral Sudirman No. ",
        recCity: 'Jakarta',
        recDistrict: 'Selatan',
        recName: 'John Doe',
        recPhone: '08123456789',
        recPostal: '12345',
        recRegency: 'Jakarta',
        recState: 'DKI Jakarta',
    }],
    startAddr: 'Jl. Jendral Sudirman No. 1',
    startCity: 'Jakarta',
    startDistrict: 'Selatan',
    startName: 'John Doe',
    startPhone: '08123456789',
    startPostal: '12345',
    startRegency: 'Jakarta',
    startState: 'DKI Jakarta',
    status: 'pickingUp',
    type: 'lastmile',
}


export const expDriverAsgs: DriverAssignmentScheme[] = [expDriverAsg, expLMDriverAsg];