import {DriverCreateScheme, DriverDetailScheme} from '../Model'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import React, {useState} from 'react'
import {BaseRowField} from '../../commons/components/BaseRowField'
import {BaseCUForm} from '../../commons/components/BaseCUForm'
import {createDriver, updateDriver} from '../Api'

const initialValue: DriverCreateScheme = {
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
}

const createValidationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
    password: Yup.string().required().label('Password'),
    confirmPassword: Yup.string().required().oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

const updateValidationSchema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
})

interface BaseDriverFormProps {
    isUpdate: boolean
    callbackCreate: (data: DriverDetailScheme|false) => void;
    callbackUpdate: (data: DriverDetailScheme|false) => void;
    callbackCancel: () => void;
}

interface DriverUpdateFormProps extends BaseDriverFormProps {
    isUpdate: true,
    data: DriverDetailScheme
}

interface DriverCreateFormProps extends BaseDriverFormProps {
    isUpdate: false,
    data?: DriverDetailScheme

}

export type DriverFormProps = DriverCreateFormProps | DriverUpdateFormProps

export const DriverForm = ({
                                isUpdate,
                                data: updateData,
                                callbackUpdate,
                                callbackCancel,
                                callbackCreate,
                            }: DriverFormProps) => {
    const updateCb = async (data: DriverDetailScheme) => {
        try {
            const res = await updateDriver(data)
            callbackUpdate(res)
        } catch (e) {
            callbackUpdate(false)
        }
    }

    const createCb = async (data: DriverCreateScheme) => {
        try {
            const res = await createDriver(data)
            callbackCreate(res)
        } catch (e) {
            callbackCreate(false)
        }
    }
    return (
        <BaseCUForm<DriverCreateScheme, DriverDetailScheme> isUpdate={isUpdate} callbackCreate={createCb}
                                                            callbackUpdate={updateCb}
                                                            callbackCancel={callbackCancel} initialValues={initialValue}
                                                            data={updateData}
                                                            createValidationSchema={createValidationSchema}
                                                            updateValidationSchema={updateValidationSchema}>
            {({touched, errors}) => (
                <>
                    <BaseRowField touched={touched} errors={errors} name={'name'} label={'Driver Name'} />
                    <BaseRowField touched={touched} errors={errors} name={'email'} label={'Driver Email'} />
                    {!isUpdate && (<>
                        <BaseRowField touched={touched} errors={errors} name={'password'} label={'Driver Password'}
                                      type={'password'} />
                        <BaseRowField touched={touched} errors={errors} name={'confirmPassword'}
                                      label={'Confirm Password'} type={'password'} />
                    </>)}

                    <BaseRowField touched={touched} errors={errors} name={'phone'} label={'Driver Phone'} />

                </>
            )}
        </BaseCUForm>
    )
}
