import {Dimension} from '../model'

const volumetricWeight = (dimension?: Dimension) => {
    if (!dimension) return 0;
    const {length, width, height} = dimension
    return (length * width * height) / 6000
}

const calculateDistanceMultiplier = (senPostal: string, recPostal: string) => {
    return 1
}

export const calculatePrice = ({weight, dimension, insurance, packageValue, senPostal, recPostal}: {
    weight: number,
    dimension: Dimension,
    insurance: boolean,
    packageValue: number,
    senPostal: string,
    recPostal: string
}) => {
    const effectiveWeight = Math.max(weight, volumetricWeight(dimension))
    const insurancePrice = insurance ? packageValue * 0.02 : 0

    return effectiveWeight * calculateDistanceMultiplier(senPostal, recPostal) * 10000 + insurancePrice
}