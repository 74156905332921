import {useNavigate, useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {
    deliverSingleLMPackage,
    getAssignmentData,
    partialPickupAssignmentPackages,
    pickupAssignmentPackages,
    scanAssignmentPackages,
} from './Api'
import {
    AssignmentPackageSchema,
    DriverAssignmentStatus,
    DriverAssignmentType,
    LastMileAssignmentPackageSchema,
} from './Model'
import {ChecklistScanner, ChecklistScannerController} from '../scanner/ChecklistScanner'
import {DriverAssignmentScanPackageListModal} from './components/DriverAssignmentScanPackageListModal'
import {PicProofScanner} from '../scanner/PicProofScanner'
import Swal from 'sweetalert2'

export const AssignmentScanner = () => {
    const {assgId} = useParams()
    const [ready, setReady] = useState<boolean>(false)
    const [assgData, setAssgData] = useState<AssignmentPackageSchema[]>()
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [openPackageList, setOpenPackageList] = useState(false)
    const scannerController = React.useRef<ChecklistScannerController>(null)
    const [assgType, setAssgType] = useState<DriverAssignmentType | null>(null)
    const [assgStatus, setAssgStatus] = useState<DriverAssignmentStatus | "">("")
    const navigate = useNavigate()

    const fetchAssignmentData = async () => {
        try {
            const data = await getAssignmentData(assgId!)
            if (!data.packages || data.packages.length === 0) {
                setErrorMsg('No packages found in assignment')
                return
            } else {
                setAssgType(data.type)
                setAssgData(data.packages)
                setAssgStatus(data.status)
                setReady(true)
            }
        } catch (e) {
            if (e instanceof Error && e.message === 'Not found') {
                setErrorMsg('Assignment not found')
            } else {
                setErrorMsg('Error fetching assignment data')
            }
        }
    }

    useEffect(() => {
        fetchAssignmentData()
    }, [])

    const allPackageScannedCb = async (complete: boolean) => {
        const scannedPackages = scannerController.current!.getScannedPackages()
        if (complete) {
            const status = await pickupAssignmentPackages(assgId!, scannerController.current!.getScannedPackages())
            if (status) {
                await Swal.fire('Success', 'All packages scanned', 'success')
                navigate(`/assignment/${assgId}`)
            } else {
                await Swal.fire('Error', 'Error submitting packages, refresh and rescan items', 'error')
            }
        } else {
            const result = await Swal.fire({
                title: 'Explain why the packages is not complete',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Submit partially',
                showLoaderOnConfirm: true,
                preConfirm: async (reason) => {
                    try {
                        const status = await partialPickupAssignmentPackages(assgId!, scannedPackages, reason)
                        if (!status) {
                            return Swal.showValidationMessage(`Error submitting packages, refresh and rescan items`)
                        }
                        return true
                    } catch (error) {
                        Swal.showValidationMessage(`Error submitting packages, refresh and rescan items`)
                    }
                },
                allowOutsideClick: () => false,
            })
            if (result.isConfirmed) {
                await Swal.fire('Success', 'Packages submitted', 'success')
                navigate(`/assignment/${assgId}`)
            } else {
                await Swal.fire('Error', 'Error submitting packages, refresh and rescan items', 'error')
            }

        }

    }

    const lmPackageFoundHandler = async (awb: string, picture: File): Promise<boolean> => {
        return await deliverSingleLMPackage(assgId!, awb, picture);
    }

    return (
        <>
            {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
            {ready && assgData && assgStatus === 'pickingUp' &&
                <ChecklistScanner packages={assgData} finishCallback={allPackageScannedCb}
                                  controlRef={scannerController} />}
            {ready && assgData && assgStatus === 'lmDelivering' &&
                <PicProofScanner packages={assgData as LastMileAssignmentPackageSchema[]}
                                 finishCallback={allPackageScannedCb}
                                 controlRef={scannerController} packageFoundCallback={lmPackageFoundHandler} />}
            <nav className="navbar fixed-bottom navbar-dark bg-light">
                <div className="container-fluid text-center d-flex justify-content-around my-2">
                    <button className={'btn btn-primary btn-lg'} type="button" onClick={() => {
                        setOpenPackageList(true)
                    }}>Package List
                    </button>
                    <button className={'btn btn-danger btn-lg'} type="button" onClick={() => {
                        allPackageScannedCb(false)
                    }}>Finish Scanning
                    </button>
                </div>
            </nav>
            {openPackageList && <DriverAssignmentScanPackageListModal assgId={assgId!} packages={assgData!}
                                                                      scannerRef={scannerController} closeCb={() => {
                setOpenPackageList(false)
            }} />}
        </>
    )
}