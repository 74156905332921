import {useNavigate, useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {DriverAssignmentScheme} from './Model'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getAssignmentData} from './Api'
import {DriverAssignmentCard} from './components/DriverAssignmentCard'
import {DriverAssignmentButton} from './components/DriverAssingmentButton'

export const AssignmentDetail = () => {
    const {assgId} = useParams()

    const [assg, setAssg] = useState<DriverAssignmentScheme | null>(null)

    const updateAssignmentData = async () => {
        try {
            setAssg(await getAssignmentData(assgId!))
        } catch (e) {
            if (e instanceof Error && e.message === 'Not found') {
                navigate('/404', {replace: true})
            }
        }

    }

    useEffect(() => {
        updateAssignmentData()
    }, [])

    const navigate = useNavigate()

    if (!assg) return (<TopBarProgress />)

    return (
        <>
            <DriverAssignmentCard assg={assg} />
            <nav className="navbar fixed-bottom navbar-dark bg-light d-md-none">
                <div className="container-fluid text-center d-flex justify-content-center">
                    <DriverAssignmentButton assg={assg}/>
                </div>
            </nav>
        </>
    )

}