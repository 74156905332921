/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, MutableRefObject, useEffect, useState} from 'react'
import {Modal, Table} from 'react-bootstrap'
import {AssignmentPackageSchema, LastMileAssignmentPackageSchema} from '../Model'
import {ChecklistScannerController} from '../../scanner/ChecklistScanner'

interface DriverAssignmentModalProps {
    scannerRef: MutableRefObject<ChecklistScannerController | null>
    assgId: string
    closeCb: () => void
    packages: AssignmentPackageSchema[] | LastMileAssignmentPackageSchema[]
}

interface PackageListSchema {
    awb: string
    status: string
    col: number
    detail?: string
}

const colorArr = ['#50cd89', '#ffc700', '#f1416c']

export const DriverAssignmentScanPackageListModal: FC<DriverAssignmentModalProps> = ({
                                                                                         assgId,
                                                                                         packages,
                                                                                         scannerRef,
                                                                                         closeCb,
                                                                                     }) => {
    const [packageList, setPackageList] = useState<PackageListSchema[]>([])
    const [selectedPackageId, setSelectedPackageId] = useState('')

    useEffect(() => {
        if (!scannerRef.current) return
        const scannedPkgs = scannerRef.current.getScannedPackages()
        const data = scannedPkgs.map<PackageListSchema>(awb => ({awb, status: 'Picked up', col: 1}))
        data?.unshift(...(packages.filter(pkg => !scannedPkgs.includes(pkg.awb)).map<PackageListSchema>(pkg => ({
            awb: pkg.awb,
            status: pkg.status === 'pending' ? 'Not picked up' : (pkg.status === 'pickedup' ? 'Picked up' : 'Missing'),
            col: pkg.status === 'pending' ? 2 : (pkg.status === 'pickedup' ? 1 : 3),
            detail: (pkg as LastMileAssignmentPackageSchema).recName ? `${(pkg as LastMileAssignmentPackageSchema).recName} - ${(pkg as LastMileAssignmentPackageSchema).recPhone} \n ${(pkg as LastMileAssignmentPackageSchema).recAddr}, ${(pkg as LastMileAssignmentPackageSchema).recDistrict}, ${(pkg as LastMileAssignmentPackageSchema).recRegency}, ${(pkg as LastMileAssignmentPackageSchema).recCity}, ${(pkg as LastMileAssignmentPackageSchema).recState} ${(pkg as LastMileAssignmentPackageSchema).recPostal}` : '',
        }))))
        setPackageList(data)
    }, [scannerRef])

    return (
        <Modal className="modal" show={true} size={'xl'}>
            <div className="modal-header justify-content-end border-0 pb-0">
            </div>

            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                <div className="mb-13 text-center">
                    <h1 className="mb-3">Package left #{assgId}</h1>
                </div>

                <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bold text-muted">
                        <th className="min-w-150px">Package Id</th>
                        <th className="min-w-150px">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {packageList.map(pkg => (
                        <>
                            <tr key={pkg.awb} onClick={() => setSelectedPackageId(pkg.awb)}>
                                <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {pkg.awb}
                                </span>
                                </td>
                                <td>
                                <span className="fw-bold d-block fs-6"
                                      style={{color: colorArr[pkg.col - 1]}}>
                                    {pkg.status}
                                </span>
                                </td>
                            </tr>
                            {pkg.detail && selectedPackageId === pkg.awb && (
                                <tr>
                                    <td colSpan={2}>
                                        <span className="fw-medium d-block fs-6">
                                            {pkg.detail}
                                        </span>
                                    </td>
                                </tr>
                                )}
                        </>
                    ))}

                    </tbody>
                </Table>

                <button className={'btn btn-primary'} onClick={closeCb}>Close</button>

            </div>
        </Modal>
)
}

