import {useState} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import {Stack} from 'react-bootstrap'

type PackageActionProps = {onClose: () => void, code: string, success?: boolean, successMsg?: string, errorMsg?: string}

export const ChecklistScannerScanNotif = ({
                                              onClose,
                                              code,
                                              success = true,
                                              successMsg,
                                              errorMsg,
                                          }: PackageActionProps) => {
    const [show, setShow] = useState(true)

    const handleClose = () => {
        setShow(false)
        onClose()
    }

    return (
        <>
            <Offcanvas show={show} backdrop={true} placement={'bottom'}
                       onHide={handleClose} style={{backgroundColor: success ? '#47be7d' : '#d9214e'}}>
                <Offcanvas.Header closeButton={true}>
                    <Offcanvas.Title>Processing</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Stack direction={'vertical'} gap={3} style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <b>AWB: {code}</b>
                        <h1 style={{color: 'white'}}>{success ? 'Success' : 'Failed'}</h1>
                        <p style={{color: 'white'}}>{success ? successMsg || 'Package scanned successfully' : errorMsg || 'Package not found'}</p>
                        <button onClick={handleClose} className={'btn btn-primary'}>Close</button>
                    </Stack>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
