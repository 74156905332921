import {HubCreateScheme, HubDetailScheme} from './Model'

export const createHub = async (hub: HubCreateScheme): Promise<HubDetailScheme> => {
    // POST /hub/
    throw new Error('Not implemented')
}

export const updateHub = async (hub: HubDetailScheme): Promise<HubDetailScheme> => {
    // PATCH /hub/:hubId
    throw new Error('Not implemented')
}

export const listHub = async (): Promise<HubDetailScheme[]> => {
    // GET /hub/
    return [{
        id: '1',
        name: 'Hub 1',
        email: 'hub1@kirim.in',
        phone: '08988282',
        city: 'string',
        state: 'string;',
        regency: 'string;',
        district: 'string;',
        postal: 'string;',
        addr: 'string;',
    }]
    // throw new Error('Not implemented')
}

export const getHub = async (id: string): Promise<HubDetailScheme> => {
    // GET /hub/:hubId
    return {
        id: '1',
        name: 'Hub 1',
        email: 'hub1@kirim.in',
        phone: '08988282',
        city: 'string',
        state: 'string;',
        regency: 'string;',
        district: 'string;',
        postal: 'string;',
        addr: 'string;',
    }
    // throw new Error('Not implemented')
}