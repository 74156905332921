import {KTIcon} from '../../../../_metronic/helpers'
import {DriverDetailScheme} from '../Model'
import {useState} from 'react'
import {DriverCUModal} from './DriverCUModal'
import {useNavigate} from 'react-router-dom'

export const DriverHeader = ({driver}: {driver: DriverDetailScheme}) => {
    const [editOpen, setEditOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    const refreshPage = () => {
        navigate(0)
    }


    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                            {driver.name}
                                        </a>
                                        <a href="#">
                                            <KTIcon iconName="verify" className="fs-1 text-primary" />
                                        </a>
                                    </div>

                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                        >
                                            <KTIcon iconName="profile-circle" className="fs-4 me-1" />
                                            Driver #{driver.id}
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                        >
                                            <KTIcon iconName="sms" className="fs-4 me-1" />
                                            {driver.phone}
                                        </a>
                                    </div>
                                </div>

                                <div className="d-flex my-4">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-primary me-3"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_offer_a_deal"
                                        onClick={() => setEditOpen(true)}
                                    >
                                        Edit
                                    </a>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap flex-stack">
                                <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap">
                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bolder">10</div>
                                            </div>

                                            <div className="fw-bold fs-6 text-gray-400">Assignments (this month)</div>
                                        </div>

                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bolder">90%</div>
                                            </div>

                                            <div className="fw-bold fs-6 text-gray-400">Success Rate</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {editOpen && <DriverCUModal isUpdate={true} callbackCreate={refreshPage} callbackUpdate={refreshPage}
                                        callbackCancel={() => setEditOpen(false)} data={driver} />}
        </>
    )
}