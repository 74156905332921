import React, {useState} from 'react'
import {FieldArray, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {PackageCreateScheme, UnpackedPackageCreateScheme} from '../../model'
import {BaseRowField} from '../../../commons/components/BaseRowField'
import {CreatePackageAddrField} from './CreatePackageAddrField'
import {BaseColField} from '../../../commons/components/BaseColField'
import {calculatePrice} from '../../services/CalculatePrice'
import {MerchantDetailScheme} from '../../../merchant/Model'

const emptyPackage = {
    'name': '',
    'phone': '',
    'addr': '',
    'postal': '',
    'city': '',
    'state': '',
    'regency': '',
    'district': '',
    'weight': 0,
    'insurance': false,
    'dimension': {
        'length': 0,
        'width': 0,
        'height': 0,
    },
    'packageValue': 0,
    'fragile': false,

}

const initialValues: PackageCreateScheme = {
    'senName': '',
    'senPhone': '',
    'senAddr': '',
    'senPostal': '',
    'senCity': '',
    'senState': '',
    'senRegency': '',
    'senDistrict': '',
    'packages': [
        {
            'name': '',
            'phone': '',
            'addr': '',
            'postal': '',
            'city': '',
            'state': '',
            'regency': '',
            'district': '',
            'weight': 0,
            'insurance': false,
            'dimension': {
                'length': 0,
                'width': 0,
                'height': 0,
            },
            'packageValue': 0,
            'fragile': false,
        },
    ],
}

const SERVICE_TYPES = ['Express', 'Regular', 'Economy']

const pkgCreateSchema = Yup.object().shape({
    senName: Yup.string()
        .max(50, 'Maximum 50 symbols')
        .required('Sender name is required'),
    senPhone: Yup.string()
        .min(6, 'Minimum 6 symbols')
        .max(20, 'Maximum 20 symbols')
        .required('Sender phone is required'),
    senAddr: Yup.string()
        .required('Sender address is required'),
    senPostal: Yup.string()
        .length(5, 'Postal code must be 5 characters')
        .required('Sender postal code is required'),
    senCity: Yup.string().required('Sender City is required'),
    senRegency: Yup.string().required('Sender City is required'),
    senState: Yup.string().required('Sender City is required'),
    senDistrict: Yup.string().required('Sender City is required'),
    packages: Yup.array().of(
        Yup.object().shape({
            name: Yup.string()
                .required('Recipient name is required'),
            phone: Yup.string()
                .min(6, 'Minimum 6 symbols')
                .max(20, 'Maximum 20 symbols')
                .required('Recipient phone is required'),
            addr: Yup.string()
                .required('Recipient address is required'),
            postal: Yup.string()
                .length(5, 'Postal code must be 5 characters')
                .required('Recipient postal code is required'),
            city: Yup.string().required('Recipient City is required'),
            regency: Yup.string().required('Recipient City is required'),
            state: Yup.string().required('Recipient City is required'),
            district: Yup.string().required('Recipient City is required'),
            weight: Yup.number().required('Weight is required').positive('Weight must be positive'),
            dimension: Yup.object().shape({
                length: Yup.number().required('Length is required').positive('Length must be positive'),
                width: Yup.number().required('Width is required').positive('Width must be positive'),
                height: Yup.number().required('Height is required').positive('Height must be positive'),
            }),
        }),
    ),

})

export const CreatePackageForm = ({onSubmit, merchant}: {onSubmit: (pkgs: UnpackedPackageCreateScheme[]) => void, merchant?: MerchantDetailScheme}) => {
    const [loading, setLoading] = useState(false)

    const processForm = (values: PackageCreateScheme) => {
        onSubmit(values.packages.map<UnpackedPackageCreateScheme>((pkg): UnpackedPackageCreateScheme => {
            return {
                senName: values.senName,
                senPhone: values.senPhone,
                senAddr: values.senAddr,
                senPostal: values.senPostal,
                senCity: values.senCity,
                senState: values.senState,
                senRegency: values.senRegency,
                senDistrict: values.senDistrict,
                recPostal: pkg.postal,
                recName: pkg.name,
                recPhone: pkg.phone,
                recAddr: pkg.addr,
                recCity: pkg.city,
                recState: pkg.state,
                recRegency: pkg.regency,
                recDistrict: pkg.district,
                weight: pkg.weight,
                dimension: pkg.dimension,
                insurance: pkg.insurance,
                fragile: pkg.fragile,
                packageValue: pkg.packageValue,
            }
        }))
    }

    const mergeMerchant = (merchant: MerchantDetailScheme): PackageCreateScheme => {
        return {
            ...initialValues,
            senName: merchant.name,
            senPhone: merchant.phone,
            senAddr: merchant.addr,
            senPostal: merchant.postal,
            senCity: merchant.city,
            senState: merchant.state,
            senRegency: merchant.regency,
            senDistrict: merchant.district,
        }
    }

    const isM = !!merchant;

    return (
        <Formik initialValues={merchant ? mergeMerchant(merchant): initialValues} onSubmit={processForm} validationSchema={pkgCreateSchema}>
            {({values, isSubmitting, isValid, status, touched, errors, handleSubmit}) => (
                <Form onSubmit={handleSubmit} className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                    {status && (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">{status}</div>
                        </div>
                    )}

                    <span className="fw-bold fs-3 mb-1">Sender Data</span>

                    <BaseRowField touched={touched} errors={errors} name={'senName'}
                                  label={'Sender Name'} />
                    <BaseRowField touched={touched} errors={errors} name={'senPhone'}
                                  label={'Sender Phone'} />
                    <CreatePackageAddrField senOrRec={'sen'} errors={errors} touched={touched} />

                    <hr />
                    <FieldArray name={'packages'}>
                        {({push, remove}) => (
                            <>
                                <div className="row mb-4">
                                    <div className="col-lg-12">
                                        <div className={'d-flex justify-content-between'}>
                                            <div className="">
                                                <span className="fw-bold fs-3 mb-1">Packages Data</span>
                                            </div>
                                            <div className="">
                                                <button type="button" onClick={() => push(emptyPackage)}
                                                        className="btn btn-primary">Create New
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {values.packages.map((pkg, idx) => (
                                    <>
                                        <div className="row mb-4">
                                            <div className="col-lg-12">
                                                <div className={'d-flex justify-content-between'}>
                                                    <div className="">
                                                        <span className="fw-bold fs-3 mb-1">Package #{idx + 1}</span>
                                                    </div>
                                                    <div className="">
                                                        <span
                                                            className="fw-medium fs-3 mb-1">Estimated price: Rp. {calculatePrice({
                                                            senPostal: values.senPostal,
                                                            recPostal: pkg.postal, ...pkg,
                                                        }) || '-'}</span>
                                                    </div>
                                                    <div className="">
                                                        <button type="button" onClick={() => remove(idx)}
                                                                className="btn btn-danger">Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <BaseRowField touched={touched} errors={errors}
                                                      name={`packages.${idx}.name`}
                                                      label={'Recipient Name'} />
                                        <BaseRowField touched={touched} errors={errors}
                                                      name={`packages.${idx}.phone`}
                                                      label={'Recipient Phone'} />
                                        <CreatePackageAddrField senOrRec={'rec'} idx={idx} prefix={'packages'}
                                                                errors={errors} touched={touched} />
                                        <BaseRowField touched={touched} errors={errors}
                                                      name={`packages.${idx}.weight`}
                                                      label={'Weight (KG)'} type={'number'} />
                                        <div className={'row mb-6'}>
                                            <label
                                                className="col-lg-2 col-form-label required fw-bolder text-dark fs-6">Dimensions</label>
                                            <BaseColField touched={touched} errors={errors}
                                                          name={`packages.${idx}.dimension.length`}
                                                          label={'Length (cm)'} gridSize={3}
                                                          className={''} />
                                            <BaseColField touched={touched} errors={errors}
                                                          name={`packages.${idx}.dimension.width`}
                                                          label={'Width (cm)'} gridSize={3} />
                                            <BaseColField touched={touched} errors={errors}
                                                          name={`packages.${idx}.dimension.height`}
                                                          label={'Height (cm)'} gridSize={3} />
                                        </div>
                                        <BaseRowField touched={touched} errors={errors}
                                                      name={`packages.${idx}.insurance`}
                                                      label={'Insure the package'} type={'checkbox'} />
                                        <BaseRowField touched={touched} errors={errors}
                                                      name={`packages.${idx}.packageValue`}
                                                      label={'Package Value'} type={'number'} />
                                        <BaseRowField touched={touched} errors={errors}
                                                      name={`packages.${idx}.fragile`}
                                                      label={'Is the package fragile'} type={'checkbox'} />
                                        <hr />
                                    </>
                                ))}
                            </>
                        )}
                    </FieldArray>


                    {/* begin::Form group */}
                    <div className="text-center">
                        <button
                            type="submit"
                            id="kt_sign_up_submit"
                            className="btn btn-lg btn-primary w-100 mb-5"
                            disabled={isSubmitting || !isValid}
                        >
                            {!loading && <span className="indicator-label">Submit</span>}
                            {loading && (
                                <span className="indicator-progress" style={{display: 'block'}}>
              Please wait...{' '}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
                            )}
                        </button>
                        <Link to="-1">
                            <button
                                type="button"
                                id="kt_login_signup_form_cancel_button"
                                className="btn btn-lg btn-light-primary w-100 mb-5"
                            >
                                Cancel
                            </button>
                        </Link>
                    </div>
                    {/* end::Form group */}
                </Form>
            )}
        </Formik>

    )
}
