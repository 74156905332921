import React, {HTMLInputTypeAttribute} from 'react'
import {ErrorMessage, Field, getIn} from 'formik'
import clsx from 'clsx'

interface CreatePackageAddrFieldProps {
    senOrRec: 'sen' | 'rec',
    idx?: number
    prefix?: string
    touched: {[key: string]: any},
    errors: {[key: string]: any},
    disabled?: boolean
}

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1)


const InternalAddrField = ({idx, prefix, senOrRec, touched, errors, name, label, disabled = false, type = 'text'}: {
    idx?: number, prefix?: string, touched: {[key: string]: boolean | boolean[]}, disabled?: boolean,
    errors: {[key: string]: boolean}, senOrRec: 'sen' | 'rec', name: string, label: string, type?: HTMLInputTypeAttribute
}) => {
    const iName = (senOrRec === 'rec') ? (prefix + '.' + idx + '.' + name) : (senOrRec + capitalize(name))
    return (
        <>
            <label className="form-label fw-bolder text-dark fs-6 required" htmlFor={iName}>{label}</label>
            <Field name={iName}
                   disabled={disabled}
                   type={type}
                   {...(type === "textarea" ? {as: "textarea"} : {})}
                   className={clsx(
                       'form-control bg-transparent',
                       {
                           'is-invalid': getIn(touched, iName) && getIn(errors, iName),
                       },
                       {
                           'is-valid': getIn(touched, iName) && !getIn(errors, iName),
                       })} />
            <ErrorMessage name={iName}
                          component={'div'} className={'fv-plugins-message-container'}>
                {(errorMessage) => (
                    <div className="fv-help-block">
                        <span role="alert">{errorMessage}</span>
                    </div>
                )}
            </ErrorMessage>
        </>
    )
}

export const CreatePackageAddrField = ({
                                           senOrRec,
                                           idx,
                                           prefix,
                                           touched,
                                           errors,
                                           disabled = false,
                                       }: CreatePackageAddrFieldProps) => {
    const isR = senOrRec === 'rec'
    return (
        <>
            <div className="row mb-8">
                <label className="col-lg-2 col-form-label required fw-bolder text-dark fs-6">Address</label>
                <div className={'col-lg-10'}>
                    <div className={'row mb-6'}>
                        <div className={'col-lg-6'}>
                            <InternalAddrField idx={idx} prefix={prefix} disabled={disabled}
                                               label={isR ? 'Recipient State' : 'Sender State'} touched={touched}
                                               errors={errors} senOrRec={senOrRec} name={'state'} />
                        </div>
                        <div className={'col-lg-6'}>
                            <InternalAddrField idx={idx} prefix={prefix} disabled={disabled}
                                               label={isR ? 'Recipient Regency' : 'Sender Regency'} touched={touched}
                                               errors={errors} senOrRec={senOrRec} name={'city'} />
                        </div>
                    </div>
                    <div className={'row mb-6'}>
                        <div className={'col-lg-6'}>
                            <InternalAddrField idx={idx} prefix={prefix} disabled={disabled}
                                               label={isR ? 'Recipient Regency' : 'Sender Regency'} touched={touched}
                                               errors={errors} senOrRec={senOrRec} name={'regency'} />
                        </div>
                        <div className={'col-lg-6'}>
                            <InternalAddrField idx={idx} prefix={prefix} disabled={disabled}
                                               label={isR ? 'Recipient District' : 'Sender District'} touched={touched}
                                               errors={errors} senOrRec={senOrRec} name={'district'} />
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-lg-6'}>
                            <InternalAddrField idx={idx} prefix={prefix} disabled={disabled}
                                               label={isR ? 'Recipient Postal Code' : 'Sender Postal Code'}
                                               touched={touched} errors={errors} senOrRec={senOrRec} name={'postal'} />
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-lg-12'}>
                            <InternalAddrField idx={idx} prefix={prefix} disabled={disabled} type={'textarea'}
                                               label={isR ? 'Recipient Address' : 'Sender Address'} touched={touched}
                                               errors={errors} senOrRec={senOrRec} name={'addr'} />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}