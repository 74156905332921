/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {HubForm, HubFormProps} from './HubForm'


export const HubCUModal: FC<HubFormProps> = ({isUpdate, data, callbackUpdate, callbackCreate, callbackCancel}) => {
    // @ts-ignore
    return (
        <Modal className="modal" show={true} size={"xl"}>
            <div className="modal-header justify-content-end border-0 pb-0">
            </div>

            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                <div className="mb-13 text-center">
                    <h1 className="mb-3">{isUpdate ? `Update Hub #${data.id}` : 'Create Hub'}</h1>
                </div>

                {isUpdate ? (<HubForm isUpdate={true} data={data} callbackUpdate={callbackUpdate}
                                           callbackCreate={callbackCreate} callbackCancel={callbackCancel} />) : (
                    <HubForm isUpdate={false} callbackUpdate={callbackUpdate} callbackCreate={callbackCreate} callbackCancel={callbackCancel} />)}

            </div>
        </Modal>
    )
}

