import axios from 'axios'
import { AuthModel, ContestModel, ContestSubmissionModel, IAccountEdit, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000"

export const USER_API_URL = `${API_URL}/auth/users/me/`
export const LOGIN_URL = `${API_URL}/auth/jwt/create/`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/users/set_password/`
export const CONTEST_LIST_URL = `${API_URL}/api/contest/`
export const SELF_SUBMISSION_URL = `${API_URL}/api/contest/me/`
export const CONTEST_SUBMISSION_URL = (contest_id: string | number) => `${API_URL}/api/contest/${contest_id}/apply/`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(USER_API_URL, {
    headers: {'Authorization': `Bearer ${token}`}
  })
}

export function changeUserPassword(token: string, current_password: string, newPassword: string) {
  return axios.post(CHANGE_PASSWORD_URL,{
    new_password: newPassword,
    current_password: current_password
  }, {
    headers: {'Authorization': `Bearer ${token}`},
  })
}

export function updateUser(token: string, newData: IAccountEdit) {
  return axios.patch(USER_API_URL,{...newData}, {
    headers: {'Authorization': `Bearer ${token}`},
  })
}

export function getContests(token: string) {
  return axios.get<ContestModel[]>(CONTEST_LIST_URL, {
    headers: {'Authorization': `Bearer ${token}`}
  })
}

export function submitContest(token: string, contestId: number, submission_link: string) {
  return axios.post(CONTEST_SUBMISSION_URL(contestId), {submission_link},{
    headers: {'Authorization': `Bearer ${token}`}
  })
}

export function getSubmissions(token: string) {
  return axios.get<ContestSubmissionModel[]>(SELF_SUBMISSION_URL, {
    headers: {'Authorization': `Bearer ${token}`}
  })
}