import React, {HTMLInputTypeAttribute} from 'react'
import {ErrorMessage, Field, getIn} from 'formik'
import clsx from 'clsx'


interface CreatePackageFieldProps {
    touched: {[key: string]: any},
    errors: {[key: string]: any},
    name: string,
    type?: HTMLInputTypeAttribute | 'select',
    label: string
    disabled?: boolean
    options?: {value: string, label: string}[]
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
}

export const BaseRowField = ({
                                 touched,
                                 errors,
                                 name,
                                 type = 'text',
                                 label,
                                 disabled = false,
                                 options,
                                 setFieldValue,
                             }: CreatePackageFieldProps) => {
    return (
        <div className="row mb-8">
            <label htmlFor={name} className="col-lg-2 col-form-label required fw-bolder text-dark fs-6">{label}</label>
            {type === 'checkbox' ? (
                <div className={'col-lg-10'}>
                    <div className="form-check form-switch form-switch-md form-check-custom form-check-solid">
                        <Field
                            type="checkbox"
                            name={name}
                            className={clsx(
                                'form-check-input col-lg-4',
                                {
                                    'is-invalid': touched[name] && errors[name],
                                },
                                {
                                    'is-valid': touched[name] && !errors[name],
                                })}
                        />
                    </div>
                </div>
            ) : (type === 'select' ? ((
                <div className={'col-lg-10'}>
                    <select name={name} onChange={(e) => {
                        setFieldValue && setFieldValue(name, e.target.value, true)
                    }} disabled={disabled} className={clsx(
                        'form-control form-select form-select-lg form-select-solid bg-transparent col-lg-4',
                        {
                            'is-invalid': getIn(touched, name) && getIn(errors, name),
                        },
                        {
                            'is-valid': getIn(touched, name) && !getIn(errors, name),
                        })}>
                        <option/>
                        {options?.map((option) => (
                            <option key={option.value} value={option.value}> {getIn(touched, name) ? "a" : "b"} - {option.label}</option>))}
                    </select>
                    <ErrorMessage name={name} component={'div'} className={'fv-plugins-message-container'}>
                        {(errorMessage) => (
                            <div className="fv-help-block">
                                <span role="alert">{errorMessage}</span>
                            </div>
                        )}
                    </ErrorMessage>
                </div>
            )) : ((
                <div className={'col-lg-10'}>
                    <Field name={name} type={type}  {...(type === 'textarea' ? {as: 'textarea'} : {})}
                           disabled={disabled} className={clsx(
                        'form-control bg-transparent col-lg-4',
                        {
                            'is-invalid': getIn(touched, name) && getIn(errors, name),
                        },
                        {
                            'is-valid': getIn(touched, name) && !getIn(errors, name),
                        })} />
                    <ErrorMessage name={name} component={'div'} className={'fv-plugins-message-container'}>
                        {(errorMessage) => (
                            <div className="fv-help-block">
                                <span role="alert">{errorMessage}</span>
                            </div>
                        )}
                    </ErrorMessage>
                </div>
            )))}

        </div>
    )
}