import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {ScanPage} from '../modules/scan'
import {CreatePackagePage} from '../modules/package/CreatePackagePage'
import {PackageList} from '../modules/package/components/PackageList'
import {MerchantList} from '../modules/merchant/MerchantList'
import {DriverList} from '../modules/driver/DriverList'
import {DriverDetail} from '../modules/driver/DriverDetail'
import {AssignmentDetail} from '../modules/driver/AssignmentDetail'
import {AssignmentScanner} from '../modules/driver/AssignmentScanner'
import {HubList} from '../modules/hub/HubList'
import {AssignmentList} from '../modules/driver/AssignmentList'
import {PackageDetailPage} from '../modules/package/PackageDetailPage'

const PrivateRoutes = () => {
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path="auth/*" element={<Navigate to="/dashboard" />} />
                {/* Pages */}
                <Route path="dashboard" element={<DashboardWrapper />} />
                <Route
                    path="crafted/account/*"
                    element={
                        <SuspensedView>
                            <AccountPage />
                        </SuspensedView>
                    }
                />
                {/*Scnner */}
                <Route
                    path="scanner"
                    element={
                        <SuspensedView>
                            <ScanPage />
                        </SuspensedView>
                    } />
                {/*Create */}
                <Route
                    path="package/create"
                    element={
                        <SuspensedView>
                            <CreatePackagePage />
                        </SuspensedView>
                    } />
                <Route
                    path="package/list"
                    element={
                        <SuspensedView>
                            <PackageList />
                        </SuspensedView>
                    } />
                <Route
                    path="package/:pkgid"
                    element={
                        <SuspensedView>
                            <PackageDetailPage />
                        </SuspensedView>
                    } />
                <Route
                    path="merchant/list"
                    element={
                        <SuspensedView>
                            <MerchantList />
                        </SuspensedView>
                    } />
                <Route
                    path="hub/list"
                    element={
                        <SuspensedView>
                            <HubList />
                        </SuspensedView>
                    } />
                <Route
                    path="driver/"
                    element={
                        <SuspensedView>
                            <DriverList />
                        </SuspensedView>
                    } />
                <Route
                    path="driver/:driverid"
                    element={
                        <SuspensedView>
                            <DriverDetail />
                        </SuspensedView>
                    } />
                <Route
                    path="assignment/:assgId"
                    element={
                        <SuspensedView>
                            <AssignmentDetail />
                        </SuspensedView>
                    } />
                <Route
                    path="assignment/"
                    element={
                        <SuspensedView>
                            <AssignmentList />
                        </SuspensedView>
                    } />
                <Route
                    path="assignment/:assgId/scanner"
                    element={
                        <SuspensedView>
                            <AssignmentScanner />
                        </SuspensedView>
                    } />

                {/* Page Not Found */}
                <Route path="*" element={<Navigate to="/404" />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
