/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Button, Card, Table} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {MerchantCUModal} from './components/MerchantCUModal'
import {MerchantDetailScheme} from './Model'


export const MerchantList: React.FC<{}> = ({}) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedMerchant, setSelectedMerchant] = useState<MerchantDetailScheme | undefined>(undefined)
    const [merchantList, setMerchantList] = useState<MerchantDetailScheme[]>([{
        id: '1',
        name: 'Merchant 1',
        email: 'merchant1@kirim.in',
        phone: '08988282',
        city: 'string',
        state: 'string;',
        regency: 'string;',
        district: 'string;',
        postal: 'string;',
        addr: 'string;',
        baseHubId: '1',
    }])

    const createCallBack = () => {
        setShowModal(false)
    }

    const updateCallBack = () => {
        setShowModal(false)
    }

    const cancelCallBack = () => {
        setShowModal(false)
        setSelectedMerchant(undefined)
    }


    useEffect(() => {
        // updateContest();
    }, [])

    return (
        <div className={'card card-xl-stretch mb-xl-8'}>
            <div className={'card-header border-0 pt-5'}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Merchant List</span>
                </h3>
                <div className="card-toolbar">
                    <Button variant={'primary'} onClick={() => {
                        setSelectedMerchant(undefined)
                        setShowModal(true)
                    }}>Create<KTIcon iconName="document"
                                     className="btn-icon fs-2 m-0" /></Button>
                </div>
            </div>


            <div className={'card-body py-3'}>
                <Card.Title>Merchant List</Card.Title>
                <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bold text-muted">
                        <th className="min-w-150px">Merchant Name</th>
                        <th className="min-w-120px">Merchant Email</th>
                        <th className="min-w-20px">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {merchantList.map(merchant => (
                        <tr key={merchant.id}>
                            <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {merchant.name}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {merchant.email}
                                </span>
                            </td>
                            <td>
                                <Button variant={'outline-primary'} onClick={() => {
                                    setSelectedMerchant(merchant)
                                    setShowModal(true)
                                }}>Edit</Button>
                            </td>
                        </tr>
                    ))}
                    <>
                        {/*{contestList.map(contest => (*/}
                        {/*    <tr>*/}

                        {/*    </tr>*/}
                        {/*))}*/}
                    </>

                    </tbody>
                </Table>
            </div>

            {showModal && (selectedMerchant ?
                <MerchantCUModal isUpdate={true} data={selectedMerchant} callbackUpdate={updateCallBack}
                                 callbackCreate={createCallBack} callbackCancel={cancelCallBack} /> :
                <MerchantCUModal isUpdate={false} callbackUpdate={updateCallBack}
                                 callbackCreate={createCallBack} callbackCancel={cancelCallBack} />)}
        </div>
    )
}

