import {MerchantCreateScheme, MerchantDetailScheme} from '../Model'
import * as Yup from 'yup'
import React, {useEffect, useState} from 'react'
import {BaseRowField} from '../../commons/components/BaseRowField'
import {BaseColField} from '../../commons/components/BaseColField'
import {BaseCUForm} from '../../commons/components/BaseCUForm'
import {createMerchant, updateMerchant} from '../Api'
import {listHub} from '../../hub/Api'

const initialValue: MerchantCreateScheme = {
    name: '',
    email: '',
    phone: '',
    addr: '',
    city: '',
    state: '',
    regency: '',
    district: '',
    postal: '',
    password: '',
    confirmPassword: '',
    baseHubId: '',
}

const createValidationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
    addr: Yup.string().required().label('Address'),
    city: Yup.string().required().label('City'),
    state: Yup.string().required().label('State'),
    regency: Yup.string().required().label('Regency'),
    district: Yup.string().required().label('District'),
    postal: Yup.string().required().label('Postal Code'),
    baseHubId: Yup.string().required().label('Base Hub Id'),
    password: Yup.string().required().label('Password'),
    confirmPassword: Yup.string().required().oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

const updateValidationSchema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
    addr: Yup.string().required().label('Address'),
    city: Yup.string().required().label('City'),
    state: Yup.string().required().label('State'),
    regency: Yup.string().required().label('Regency'),
    district: Yup.string().required().label('District'),
    postalCode: Yup.string().required().label('Postal Code'),
    postal: Yup.string().required().label('Postal Code'),
    baseHubId: Yup.string().required().label('Base Hub Id'),

})

interface BaseMerchantFormProps {
    isUpdate: boolean
    callbackCreate: (data: MerchantDetailScheme | false) => void;
    callbackUpdate: (data: MerchantDetailScheme | false) => void;
    callbackCancel: () => void;
}

interface MerchantUpdateFormProps extends BaseMerchantFormProps {
    isUpdate: true,
    data: MerchantDetailScheme
}

interface MerchantCreateFormProps extends BaseMerchantFormProps {
    isUpdate: false,
    data?: MerchantDetailScheme

}

export type MerchantFormProps = MerchantCreateFormProps | MerchantUpdateFormProps

export const MerchantForm = ({
                                 isUpdate,
                                 data: updateData,
                                 callbackUpdate,
                                 callbackCancel,
                                 callbackCreate,
                             }: MerchantFormProps) => {

    const [hubList, setHubList] = useState<{value: string, label: string}[]>([])

    const updateHubList = async () => {
        const hubList = await listHub()
        setHubList(hubList.map(hub => ({value: hub.id, label: hub.name})))
    }

    useEffect(() => {
        updateHubList()
    }, [])

    const updateCb = async (data: MerchantDetailScheme) => {
        try {
            const res = await updateMerchant(data)
            callbackUpdate(res)
        } catch (e) {
            callbackUpdate(false)
        }
    }

    const createCb = async (data: MerchantCreateScheme) => {
        try {
            const res = await createMerchant(data)
            callbackCreate(res)
        } catch (e) {
            callbackCreate(false)
        }
    }
    return (
        <BaseCUForm<MerchantCreateScheme, MerchantDetailScheme> isUpdate={isUpdate} callbackCreate={createCb}
                                                                callbackUpdate={updateCb}
                                                                callbackCancel={callbackCancel}
                                                                initialValues={initialValue}
                                                                data={updateData}
                                                                createValidationSchema={createValidationSchema}
                                                                updateValidationSchema={updateValidationSchema}>
            {({touched, errors, setFieldValue, values}) => (
                <>
                    <pre>
                        {JSON.stringify(values, null, 2)}
                    </pre>
                    <BaseRowField touched={touched} errors={errors} name={'name'} label={'Merchant Name'} />
                    <BaseRowField touched={touched} errors={errors} name={'email'} label={'Merchant Email'} />
                    {!isUpdate && (<>
                        <BaseRowField touched={touched} errors={errors} name={'password'} label={'Merchant Password'}
                                      type={'password'} />
                        <BaseRowField touched={touched} errors={errors} name={'confirmPassword'}
                                      label={'Confirm Password'} type={'password'} />
                    </>)}

                    <BaseRowField touched={touched} errors={errors} name={'phone'} label={'Merchant Phone'} />

                    <BaseRowField touched={touched} errors={errors} type={'select'} name={'baseHubId'}
                                  label={'Merchant Nearest Hub'} options={hubList} setFieldValue={setFieldValue} />

                    <div className="row mb-8">
                        <label className="col-lg-2 col-form-label required fw-bolder text-dark fs-6">Address</label>
                        <div className={'col-lg-10'}>
                            <div className={'row mb-6'}>
                                <BaseColField label={'Merchant State'} touched={touched} errors={errors}
                                              name={'state'} gridSize={6} />
                                <BaseColField label={'Merchant City'} touched={touched} errors={errors}
                                              name={'city'} gridSize={6} />
                            </div>
                            <div className={'row mb-6'}>
                                <BaseColField label={'Merchant Regency'} touched={touched} errors={errors}
                                              name={'regency'} gridSize={6} />
                                <BaseColField label={'Merchant District'} touched={touched} errors={errors}
                                              name={'district'} gridSize={6} />
                            </div>
                            <div className={'row'}>
                                <BaseColField label={'Merchant Postal Code'} touched={touched} errors={errors}
                                              name={'postal'} gridSize={6} />
                            </div>
                            <div className={'row'}>
                                <BaseColField label={'Merchant Address'} touched={touched} errors={errors}
                                              name={'addr'} gridSize={12} type={'textarea'} />
                            </div>
                        </div>
                    </div>

                </>
            )}
        </BaseCUForm>
    )
}