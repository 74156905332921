import React from 'react'

interface FormSubmitCancelBtnsProps {
    isSubmitting: boolean,
    isValid: boolean,
    loading: boolean,
    callbackCancel: () => void
}

export const FormSubmitCancelBtn = ({isSubmitting, callbackCancel, loading, isValid}: FormSubmitCancelBtnsProps) => {
    return (
        <div className="text-center">
            <button
                type="submit"
                id="kt_sign_up_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={isSubmitting || !isValid}
            >
                {!loading && <span className="indicator-label">Submit</span>}
                {loading && (
                    <span className="indicator-progress" style={{display: 'block'}}> Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                )}
            </button>
            <button
                type="button"
                id="kt_login_signup_form_cancel_button"
                className="btn btn-lg btn-light-primary w-100 mb-5"
                onClick={callbackCancel}
            >
                Cancel
            </button>
        </div>
    )
}