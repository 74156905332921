/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
    const intl = useIntl()

    return (
        <>
            <SidebarMenuItem
                to="/dashboard"
                icon="element-11"
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            />
            <SidebarMenuItem
                to="/scanner"
                icon="scan-barcode"
                title={'Scanner'}
            />
            <SidebarMenuItemWithSub to={'/package/*'} title={'Package'} icon={'archive'}>
                <SidebarMenuItem to={'/package/list'} title={'List'} icon={'search-list'} />
                <SidebarMenuItem to={'/package/create'} title={'Create'} icon={'add-files'} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItem to={'/merchant/list'} title={'Merchants'} icon={'shop'} />
            <SidebarMenuItem to={'/hub/list'} title={'Hubs'} icon={'home-3'} />
            <SidebarMenuItem to={'/driver/'} title={'Drivers'} icon={'truck'} />
            <SidebarMenuItem to={'/assignment'} title={'Assignment'} icon={'notepad'} />
            <SidebarMenuItem to={'/incoming'} title={'Incoming Shipment'} icon={'arrow-down'} />
            <SidebarMenuItem to={'/users'} title={'User Management'} icon={'profile-user'} />
        </>
    )
}

export {SidebarMenuMain}
