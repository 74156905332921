import {MerchantCreateScheme, MerchantDetailScheme} from './Model'

export const createMerchant = async (driver: MerchantCreateScheme): Promise<MerchantDetailScheme> => {
    // POST /merchant/
    throw new Error('Not implemented')
}

export const updateMerchant = async (driver: MerchantDetailScheme): Promise<MerchantDetailScheme> => {
    // PATCH /merchant/:merchantId
    throw new Error('Not implemented')
}
