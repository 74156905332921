import {Button, Card, Table} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Dropdown1} from '../../../../../_metronic/partials/dropdown/Dropdown1'
import React, {useState} from 'react'
import {CreatePackageForm} from './CreatePackageForm'
import {VerifyModal} from './VerifyModal'
import {PackageCreateScheme, UnpackedPackageCreateScheme} from '../../model'
import Swal from "sweetalert2";
import {useNavigate} from 'react-router-dom'
import {createPackages} from '../../api'
import {AWBPrinter} from './AWBPrinter'


export const CreatePackage = ({}) => {
    const [data, setData] = useState<UnpackedPackageCreateScheme[]>([]);
    const [showModal, setShowModal] = useState(false);
    const nav = useNavigate();
    return (
        <div className={'card card-xl-stretch mb-xl-8'}>
            <div className={'card-header border-0 pt-5'}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Create Package</span>
                </h3>
            </div>


            <div className={'card-body py-3'}>
                <CreatePackageForm onSubmit={(pkgs) => {
                    setData(pkgs);
                    setShowModal(true);
                }}/>
                {showModal && data && <VerifyModal pkgs={data} callback={async (cont) => {
                    if (!cont) {
                        setShowModal(false);
                        return;
                    }
                    const createdData = await createPackages(data);
                    await Swal.fire({
                        title: 'Success!',
                        text: 'Your package has been successfully created.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Print aWB',
                    });
                    await AWBPrinter(createdData);
                    nav('/dashboard');
                }}/>}

            </div>


        </div>
    )
}