/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Button, Card, Table} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {DriverCUModal} from './components/DriverCUModal'
import {DriverDetailScheme} from './Model'
import {useNavigate} from 'react-router-dom'
import {listDriver} from './Api'


export const DriverList: React.FC<{}> = ({}) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedDriver, setSelectedDriver] = useState<DriverDetailScheme | undefined>(undefined)
    const [driverList, setDriverList] = useState<DriverDetailScheme[]>([])

    const updateDriverList = async () => {
        setDriverList(await listDriver());
    }

    const navigate = useNavigate();

    const createCallBack = () => {
        setShowModal(false)
    }

    const updateCallBack = () => {
        setShowModal(false)
    }

    const cancelCallBack = () => {
        setShowModal(false)
        setSelectedDriver(undefined)
    }


    useEffect(() => {
        updateDriverList()
    }, [])

    return (
        <div className={'card card-xl-stretch mb-xl-8'}>
            <div className={'card-header border-0 pt-5'}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Drivers List</span>
                </h3>
                <div className="card-toolbar">
                    <Button variant={'primary'} onClick={() => {
                        setSelectedDriver(undefined)
                        setShowModal(true)
                    }}>Create<KTIcon iconName="document"
                                     className="btn-icon fs-2 m-0" /></Button>
                </div>
            </div>


            <div className={'card-body py-3'}>
                <Card.Title>Merchant List</Card.Title>
                <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bold text-muted">
                        <th className="min-w-150px">Driver Name</th>
                        <th className="min-w-120px">Driver Email</th>
                        <th className="min-w-120px">Driver Phone</th>
                        <th className="min-w-20px">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {driverList.map(driver => (
                        <tr key={driver.id} className={"cursor-pointer"} style={{cursor: "pointer !important"}} onClick={() => {
                            navigate(driver.id)
                        }}>
                            <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {driver.name}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {driver.email}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {driver.phone}
                                </span>
                            </td>
                            <td>
                                <Button variant={'outline-primary'} onClick={() => {
                                    setSelectedDriver(driver)
                                    setShowModal(true)
                                }}>Edit</Button>
                            </td>
                        </tr>
                    ))}
                    <>
                    </>

                    </tbody>
                </Table>
            </div>

            {showModal && (selectedDriver ?
                <DriverCUModal isUpdate={true} data={selectedDriver} callbackUpdate={updateCallBack}
                               callbackCreate={createCallBack} callbackCancel={cancelCallBack} /> :
                <DriverCUModal isUpdate={false} callbackUpdate={updateCallBack}
                               callbackCreate={createCallBack} callbackCancel={cancelCallBack} />)}
        </div>
    )
}

