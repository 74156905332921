/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Button, Card, Table} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {HubCUModal} from './components/HubCUModal'
import {HubDetailScheme} from './Model'
import {listHub} from './Api'


export const HubList: React.FC<{}> = ({}) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedHub, setSelectedHub] = useState<HubDetailScheme | undefined>(undefined)
    const [hubList, setHubList] = useState<HubDetailScheme[]>([])

    const updateHubList = async () => {
        setHubList(await listHub());
    }

    useEffect(() => {
        updateHubList();
    }, [])

    const createCallBack = () => {
        setShowModal(false)
    }

    const updateCallBack = () => {
        setShowModal(false)
    }

    const cancelCallBack = () => {
        setShowModal(false)
        setSelectedHub(undefined)
    }


    return (
        <div className={'card card-xl-stretch mb-xl-8'}>
            <div className={'card-header border-0 pt-5'}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Hub List</span>
                </h3>
                <div className="card-toolbar">
                    <Button variant={'primary'} onClick={() => {
                        setSelectedHub(undefined)
                        setShowModal(true)
                    }}>Create<KTIcon iconName="document"
                                     className="btn-icon fs-2 m-0" /></Button>
                </div>
            </div>


            <div className={'card-body py-3'}>
                <Card.Title>Hub List</Card.Title>
                <Table responsive className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bold text-muted">
                        <th className="min-w-150px">Hub Name</th>
                        <th className="min-w-120px">Hub City and State</th>
                        <th className="min-w-20px">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {hubList.map(hub => (
                        <tr key={hub.id}>
                            <td>
                                <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                                    {hub.name}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                                    {hub.city}, {hub.state}
                                </span>
                            </td>
                            <td>
                                <Button variant={'outline-primary'} onClick={() => {
                                    setSelectedHub(hub)
                                    setShowModal(true)
                                }}>Edit</Button>
                            </td>
                        </tr>
                    ))}
                    <>
                    </>

                    </tbody>
                </Table>
            </div>

            {showModal && (selectedHub ?
                <HubCUModal isUpdate={true} data={selectedHub} callbackUpdate={updateCallBack}
                            callbackCreate={createCallBack} callbackCancel={cancelCallBack} /> :
                <HubCUModal isUpdate={false} callbackUpdate={updateCallBack}
                            callbackCreate={createCallBack} callbackCancel={cancelCallBack} />)}
        </div>
    )
}

